<template>
    <div v-if="canvas" class="is-flex canvas-detail" :class="{'bd-all':!thumbnail}">
        <div class="license">
            <img src="@/assets/waterMark.png">
        </div>
        
        <div class="is-flex flex-dc flex-xl bd-r">
            <div class="is-flex flex-l bd-bt">
                <div class="is-flex flex-dc flex-m bd-r">
                    <div class="flex-m pd-2 bd-bt">
                        <div class="is-flex flex-js-between">
                            <p class="has-text-left has-text-weight-medium is-size-6">
                                {{getTitle(canvas.pattern,0)}}  
                            </p>
                            <b-icon
                                pack="fas"
                                :icon="getIcon(canvas.pattern,0)"
                            ></b-icon>
                        </div>
                         <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,0))}}</p>
                        <Comment @fetchCanvas="fetchCanvas" :canvas="canvas" @statusSave="statusSave"  @setLoadding="setLoadding" size="s" :index="0" :thumbnail="thumbnail"/>
                    </div>
                    <div class="flex-m pd-2 bd-bt">
                        <div class="is-flex flex-js-between">
                            <p class="has-text-left has-text-weight-medium is-size-6">
                                {{getTitle(canvas.pattern,1)}}  
                            </p>
                            <b-icon
                                pack="fas"
                                :icon="getIcon(canvas.pattern,1)"
                            ></b-icon>
                        </div>
                        <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,1))}}</p>
                        <Comment @fetchCanvas="fetchCanvas" :canvas="canvas" @statusSave="statusSave"  @setLoadding="setLoadding" size="s" :index="1" :thumbnail="thumbnail"/>
                    </div>
                    <div class="flex-m pd-2 bd-bt">
                        <div class="is-flex flex-js-between">
                            <p class="has-text-left has-text-weight-medium is-size-6">
                                {{getTitle(canvas.pattern,2)}}  
                            </p>
                            <b-icon
                                pack="fas"
                                :icon="getIcon(canvas.pattern,2)"
                            ></b-icon>
                        </div>
                        <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,2))}}</p>
                        <Comment @fetchCanvas="fetchCanvas" :canvas="canvas" @statusSave="statusSave"  @setLoadding="setLoadding" size="s" :index="2" :thumbnail="thumbnail"/>
                    </div>
                    <div class="flex-m pd-2">
                        <div class="is-flex flex-js-between">
                            <p class="has-text-left has-text-weight-medium is-size-6">
                                {{getTitle(canvas.pattern,3)}}  
                            </p>
                            <b-icon
                                pack="fas"
                                :icon="getIcon(canvas.pattern,3)"
                            ></b-icon>
                        </div>
                        <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,3))}}</p>
                        <Comment @fetchCanvas="fetchCanvas" :canvas="canvas" @statusSave="statusSave"  @setLoadding="setLoadding" size="s" :index="3" :thumbnail="thumbnail"/>
                    </div>
                </div>
                <div class="is-flex flex-dc flex-m bd-r">
                    <div class="flex-m pd-2 bd-bt">
                        <div class="is-flex flex-js-between">
                            <p class="has-text-left has-text-weight-medium is-size-6">
                                {{getTitle(canvas.pattern,4)}}  
                            </p>
                            <b-icon
                                pack="fas"
                                :icon="getIcon(canvas.pattern,4)"
                            ></b-icon>
                        </div>
                        <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,4))}}</p>
                        <Comment @fetchCanvas="fetchCanvas" :canvas="canvas" @statusSave="statusSave"  @setLoadding="setLoadding" size="s" :index="4" :thumbnail="thumbnail"/>
                    </div>
                    <div class="flex-m pd-2 bd-bt">
                        <div class="is-flex flex-js-between">
                            <p class="has-text-left has-text-weight-medium is-size-6">
                                {{getTitle(canvas.pattern,5)}}  
                            </p>
                            <b-icon
                                pack="fas"
                                :icon="getIcon(canvas.pattern,5)"
                            ></b-icon>
                        </div>
                        <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,5))}}</p>
                        <Comment @fetchCanvas="fetchCanvas" :canvas="canvas" @statusSave="statusSave"  @setLoadding="setLoadding" size="s" :index="5" :thumbnail="thumbnail"/>
                    </div>
                    <div class="flex-m pd-2">
                        <div class="is-flex flex-js-between">
                            <p class="has-text-left has-text-weight-medium is-size-6">
                                {{getTitle(canvas.pattern,6)}}  
                            </p>
                            <b-icon
                                pack="fas"
                                :icon="getIcon(canvas.pattern,6)"
                            ></b-icon>
                        </div>
                        <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,6))}}</p>
                        <Comment @fetchCanvas="fetchCanvas" :canvas="canvas" @statusSave="statusSave"  @setLoadding="setLoadding" size="s" :index="6" :thumbnail="thumbnail"/>
                    </div>
                </div>
                <div class="is-flex flex-dc flex-m bd-r">
                    <div class="flex-m pd-2 bd-bt">
                        <div class="is-flex flex-js-between">
                            <p class="has-text-left has-text-weight-medium is-size-6">
                                {{getTitle(canvas.pattern,7)}}  
                            </p>
                            <b-icon
                                pack="fas"
                                :icon="getIcon(canvas.pattern,7)"
                            ></b-icon>
                        </div>
                        <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,7))}}</p>
                        <Comment @fetchCanvas="fetchCanvas" :canvas="canvas" @statusSave="statusSave"  @setLoadding="setLoadding" size="m" :index="7" :thumbnail="thumbnail"/>
                    </div>
                    <div class="flex-m pd-2">
                        <div class="is-flex flex-js-between">
                            <p class="has-text-left has-text-weight-medium is-size-6">
                                {{getTitle(canvas.pattern,8)}}  
                            </p>
                            <b-icon
                                pack="fas"
                                :icon="getIcon(canvas.pattern,8)"
                            ></b-icon>
                        </div>
                        <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,8))}}</p>
                        <Comment @fetchCanvas="fetchCanvas" :canvas="canvas" @statusSave="statusSave"  @setLoadding="setLoadding" size="m" :index="8" :thumbnail="thumbnail"/>
                    </div>
                </div>
                <div class="is-flex flex-dc flex-m">
                    <div class="flex-m pd-2 bd-bt">
                        <div class="is-flex flex-js-between">
                            <p class="has-text-left has-text-weight-medium is-size-6">
                                {{getTitle(canvas.pattern,9)}}  
                            </p>
                            <b-icon
                                pack="fas"
                                :icon="getIcon(canvas.pattern,9)"
                            ></b-icon>
                        </div>
                        <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,9))}}</p>
                        <Comment @fetchCanvas="fetchCanvas" :canvas="canvas" @statusSave="statusSave"  @setLoadding="setLoadding" size="m" :index="9" :thumbnail="thumbnail"/>
                    </div>
                    <div class="flex-m pd-2 bd-bt">
                        <div class="is-flex flex-js-between">
                            <p class="has-text-left has-text-weight-medium is-size-6">
                                {{getTitle(canvas.pattern,10)}}  
                            </p>
                            <b-icon
                                pack="fas"
                                :icon="getIcon(canvas.pattern,10)"
                            ></b-icon>
                        </div>
                        <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,10))}}</p>
                        <Comment @fetchCanvas="fetchCanvas" :canvas="canvas" @statusSave="statusSave"  @setLoadding="setLoadding" size="m" :index="10" :thumbnail="thumbnail"/>
                    </div>
                    <div class="flex-m pd-2">
                        <div class="is-flex flex-js-between">
                            <p class="has-text-left has-text-weight-medium is-size-6">
                                {{getTitle(canvas.pattern,11)}}  
                            </p>
                            <b-icon
                                pack="fas"
                                :icon="getIcon(canvas.pattern,11)"
                            ></b-icon>
                        </div>
                        <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,11))}}</p>
                        <Comment @fetchCanvas="fetchCanvas" :canvas="canvas" @statusSave="statusSave"  @setLoadding="setLoadding" size="m" :index="11" :thumbnail="thumbnail"/>
                    </div>
                </div>
            </div>
            <div class="is-flex flex-s">
                <div class="flex-m pd-2 bd-r">
                    <div class="is-flex flex-js-between">
                        <p class="has-text-left has-text-weight-medium is-size-6">
                            {{getTitle(canvas.pattern,12)}}  
                        </p>
                        <b-icon
                            pack="fas"
                            :icon="getIcon(canvas.pattern,12)"
                        ></b-icon>
                    </div>
                    <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,12))}}</p>
                    <Comment @fetchCanvas="fetchCanvas" :canvas="canvas" @statusSave="statusSave"  @setLoadding="setLoadding" size="s" :index="12" :thumbnail="thumbnail"/>
                </div>
                <div class="flex-m pd-2">
                    <div class="is-flex flex-js-between">
                        <p class="has-text-left has-text-weight-medium is-size-6">
                            {{getTitle(canvas.pattern,13)}}  
                        </p>
                        <b-icon
                            pack="fas"
                            :icon="getIcon(canvas.pattern,13)"
                        ></b-icon>
                    </div>
                    <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,13))}}</p>
                    <Comment @fetchCanvas="fetchCanvas" :canvas="canvas" @statusSave="statusSave"  @setLoadding="setLoadding" size="s" :index="13" :thumbnail="thumbnail"/>
                </div>
            </div>
        </div>
        <div class="is-flex flex-dc flex-s">
            <div class="flex-m pd-2 bd-bt">
                <div class="is-flex flex-js-between">
                    <p class="has-text-left has-text-weight-medium is-size-6">
                        {{getTitle(canvas.pattern,14)}}  
                    </p>
                    <b-icon
                        pack="fas"
                        :icon="getIcon(canvas.pattern,14)"
                    ></b-icon>
                </div>
                <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,14))}}</p>
                <Comment @fetchCanvas="fetchCanvas" :canvas="canvas" @statusSave="statusSave"  @setLoadding="setLoadding" size="m" :index="14" :thumbnail="thumbnail"/>
            </div>
            <div class="flex-m pd-2 bd-bt">
                <div class="is-flex flex-js-between">
                    <p class="has-text-left has-text-weight-medium is-size-6">
                        {{getTitle(canvas.pattern,15)}}  
                    </p>
                    <b-icon
                        pack="fas"
                        :icon="getIcon(canvas.pattern,15)"
                    ></b-icon>
                </div>
                <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,15))}}</p>
                <Comment @fetchCanvas="fetchCanvas" :canvas="canvas" @statusSave="statusSave"  @setLoadding="setLoadding" size="m" :index="15" :thumbnail="thumbnail"/>
            </div>
            <div class="flex-m pd-2">
                <div class="is-flex flex-js-between">
                    <p class="has-text-left has-text-weight-medium is-size-6">
                        {{getTitle(canvas.pattern,16)}}  
                    </p>
                    <b-icon
                        pack="fas"
                        :icon="getIcon(canvas.pattern,16)"
                    ></b-icon>
                </div>
                 <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,16))}}</p>
                <Comment @fetchCanvas="fetchCanvas" :canvas="canvas" @statusSave="statusSave"  @setLoadding="setLoadding" size="m"  :index="16" :thumbnail="thumbnail"/>
            </div>
        </div>
     
    </div>
</template>

<script>
import Comment from './Comment'
export default {
    components:{
        Comment
    },
    props:{
        thumbnail:{
            type:Boolean,
            default(){
                return false
            }
        },
        canvas:{
            type:Object,
            default(){
                return null
            }
        }
    },
    methods: {
        statusSave(status){
            this.$emit('statusSave',status)
        },
        setLoadding(status){
            this.$emit('setLoadding',status)
        },
        fetchCanvas(){
            this.$emit('fetchCanvas')
        }
    },
}
</script>