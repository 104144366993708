<template>
    <div v-if="canvas" class="is-flex flex-dc canvas-detail" :class="{'bd-all':!thumbnail}">
        <div class="license">
            <img src="@/assets/img/logo-integreat.png">
        </div>
        
        <div class="is-flex flex-m bd-bt">
            <div class="flex-m is-flex flex-dc bd-r">
                <div class="flex-m pd-2">
                    <div class="is-flex flex-js-between">
                        <p class="has-text-left has-text-weight-medium is-size-6">
                            {{getTitle(canvas.pattern,getIndex(canvas.pattern,0))}}  
                        </p>
                        <b-icon
                            :pack="(canvas.pattern === 'bmc')?'fas':null"
                            :icon="getIcon(canvas.pattern,getIndex(canvas.pattern,0))"
                        ></b-icon>
                    </div>
                    <p v-if="canvas.pattern === 'lean'" class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,0))}}</p>
                    <Comment :canvas="canvas" @fetchCanvas="fetchCanvas" @statusSave="statusSave"   @setLoadding="setLoadding" :size="(canvas.pattern === 'bmc')?'xl':'l'" :index="getIndex(canvas.pattern,0)" :thumbnail="thumbnail"/>
                </div>
                <div v-if="canvas.pattern === 'lean'" class="flex-s  pd-2">
                    <div class="is-flex flex-js-between">
                        <p class="has-text-left has-text-weight-medium is-size-6">
                            {{getTitle(canvas.pattern,getIndex(canvas.pattern,1))}}
                        </p>
                        <b-icon
                            :pack="(canvas.pattern === 'bmc')?'fas':null"
                            :icon="getIcon(canvas.pattern,getIndex(canvas.pattern,1))"
                        ></b-icon>
                    </div>
                    <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,1))}}</p>
                    <Comment :canvas="canvas" @fetchCanvas="fetchCanvas" @statusSave="statusSave"  size="s"  @setLoadding="setLoadding" :index="getIndex(canvas.pattern,1)" :thumbnail="thumbnail"/>
                </div>
            </div>
            <div class="flex-m is-flex flex-dc bd-r">
                <div class="flex-m  pd-2 bd-bt">
                    <div class="is-flex flex-js-between">
                        <p class="has-text-left has-text-weight-medium is-size-6">
                            {{getTitle(canvas.pattern,getIndex(canvas.pattern,2))}}
                        </p>
                        <b-icon
                            
                            :pack="(canvas.pattern === 'bmc')?'fas':null"
                            :icon="getIcon(canvas.pattern,getIndex(canvas.pattern,2))"
                        ></b-icon>
                    </div>
                    <p v-if="canvas.pattern === 'lean'" class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,2))}}</p>
                    <Comment :canvas="canvas" @fetchCanvas="fetchCanvas" @statusSave="statusSave"  size="m"  @setLoadding="setLoadding" :index="getIndex(canvas.pattern,2)" :thumbnail="thumbnail"/>
                </div>
                <div class="flex-m  pd-2">
                    <div class="is-flex flex-js-between">
                        <p class="has-text-left has-text-weight-medium is-size-6">
                            {{getTitle(canvas.pattern,getIndex(canvas.pattern,3))}}
                        </p>
                        <b-icon
                            
                            :pack="(canvas.pattern === 'bmc')?'fas':null"
                            :icon="getIcon(canvas.pattern,getIndex(canvas.pattern,3))"
                        ></b-icon>
                    </div>
                    <p v-if="canvas.pattern === 'lean'" class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,3))}}</p>
                    <Comment :canvas="canvas" @fetchCanvas="fetchCanvas" @statusSave="statusSave"  size="m"  @setLoadding="setLoadding" :index="getIndex(canvas.pattern,3)" :thumbnail="thumbnail"/>
                </div>
            </div>
            <div class="flex-m is-flex flex-dc bd-r">
                <div class="flex-m  pd-2">
                    <div class="is-flex flex-js-between">
                        <p class="has-text-left has-text-weight-medium is-size-6">
                            {{getTitle(canvas.pattern,getIndex(canvas.pattern,4))}}
                        </p>
                        <b-icon
                            
                            :pack="(canvas.pattern === 'bmc')?'fas':'fas'"
                            :icon="getIcon(canvas.pattern,getIndex(canvas.pattern,4))"
                        ></b-icon>
                    </div>
                    <p v-if="canvas.pattern === 'lean'" class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,4))}}</p>
                    <Comment :canvas="canvas" @fetchCanvas="fetchCanvas" @statusSave="statusSave"   @setLoadding="setLoadding" :size="(canvas.pattern === 'bmc')?'xl':'l'" :index="getIndex(canvas.pattern,4)" :thumbnail="thumbnail"/>
                </div>
                <div v-if="canvas.pattern === 'lean'" class="flex-s  pd-2">
                    <div class="is-flex flex-js-between">
                        <p class="has-text-left has-text-weight-medium is-size-6">
                            {{getTitle(canvas.pattern,getIndex(canvas.pattern,5))}}
                        </p>
                        <b-icon
                            :pack="(canvas.pattern === 'bmc')?'fas':null"
                            :icon="getIcon(canvas.pattern,getIndex(canvas.pattern,5))"
                        ></b-icon>
                    </div>
                    <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,5))}}</p>
                    <Comment :canvas="canvas" @fetchCanvas="fetchCanvas" @statusSave="statusSave"  size="s"  @setLoadding="setLoadding" :index="getIndex(canvas.pattern,5)" :thumbnail="thumbnail"/>
                </div>
            </div>
            <div class="flex-m is-flex flex-dc bd-r">
                <div class="flex-m pd-2 bd-bt">
                    <div class="is-flex flex-js-between">
                        <p class="has-text-left has-text-weight-medium is-size-6">
                            {{getTitle(canvas.pattern,getIndex(canvas.pattern,6))}}
                        </p>
                        <b-icon
                            :pack="(canvas.pattern === 'bmc')?'fas':null"
                            :icon="getIcon(canvas.pattern,getIndex(canvas.pattern,6))"
                        ></b-icon>
                    </div>
                    <p v-if="canvas.pattern === 'lean'" class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,6))}}</p>
                    <Comment :canvas="canvas" @fetchCanvas="fetchCanvas" @statusSave="statusSave"  size="m"  @setLoadding="setLoadding" :index="getIndex(canvas.pattern,6)" :thumbnail="thumbnail"/>
                </div>
                <div class="flex-m pd-2">
                    <div class="is-flex flex-js-between">
                        <p class="has-text-left has-text-weight-medium is-size-6">
                            {{getTitle(canvas.pattern,getIndex(canvas.pattern,7))}}
                        </p>
                        <b-icon
                            :pack="(canvas.pattern === 'bmc')?'fas':null"
                            :icon="getIcon(canvas.pattern,getIndex(canvas.pattern,7))"
                        ></b-icon>
                    </div>
                    <p v-if="canvas.pattern === 'lean'" class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,7))}}</p>
                    <Comment :canvas="canvas" @fetchCanvas="fetchCanvas" @statusSave="statusSave"  size="m"  @setLoadding="setLoadding" :index="getIndex(canvas.pattern,7)" :thumbnail="thumbnail"/>
                </div>
            </div>
            <div class="flex-m is-flex flex-dc">
                <div class="flex-m pd-2">
                    <div class="is-flex flex-js-between">
                        <p class="has-text-left has-text-weight-medium is-size-6">
                            {{getTitle(canvas.pattern,getIndex(canvas.pattern,8))}}
                        </p>
                        <b-icon
                            
                            :pack="(canvas.pattern === 'bmc')?'fas':null"
                            :icon="getIcon(canvas.pattern,getIndex(canvas.pattern,8))"
                        ></b-icon>
                    </div>
                    <p v-if="canvas.pattern === 'lean'" class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,8))}}</p>
                    <Comment :canvas="canvas" @fetchCanvas="fetchCanvas" @statusSave="statusSave"   @setLoadding="setLoadding" :size="(canvas.pattern === 'bmc')?'xl':'l'" :index="getIndex(canvas.pattern,8)" :thumbnail="thumbnail"/>
                </div>
                <div v-if="canvas.pattern === 'lean'" class="flex-s pd-2">  
                    <div class="is-flex flex-js-between">
                        <p class="has-text-left has-text-weight-medium is-size-6">
                            {{getTitle(canvas.pattern,getIndex(canvas.pattern,9))}}
                        </p>
                        <b-icon
                            :pack="(canvas.pattern === 'bmc')?'fas':null"
                            :icon="getIcon(canvas.pattern,getIndex(canvas.pattern,9))"
                        ></b-icon>
                    </div>
                    <p class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,9))}}</p>
                    <Comment :canvas="canvas" @fetchCanvas="fetchCanvas" @statusSave="statusSave"  size="s"  @setLoadding="setLoadding" :index="getIndex(canvas.pattern,9)" :thumbnail="thumbnail"/>
                </div>
            </div>
        </div>
        <div class="is-flex w-100 flex-s">
            <div class="flex-m bd-r pd-2">
                <div class="is-flex flex-js-between">
                        <p class="has-text-left has-text-weight-medium is-size-6">
                            {{getTitle(canvas.pattern,getIndex(canvas.pattern,10))}}
                        </p>
                        <b-icon
                            :pack="(canvas.pattern === 'bmc')?'fas':null"
                            :icon="getIcon(canvas.pattern,getIndex(canvas.pattern,10))"
                        ></b-icon>
                    </div>
                <p v-if="canvas.pattern === 'lean'" class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,getIndex(canvas.pattern,10))}}</p>
                <Comment :canvas="canvas" @fetchCanvas="fetchCanvas" @statusSave="statusSave"  size="s"  @setLoadding="setLoadding" :index="getIndex(canvas.pattern,10)" :thumbnail="thumbnail"/>
            </div>
            <div class="flex-m pd-2">
                <div class="is-flex flex-js-between">
                    <p class="has-text-left has-text-weight-medium is-size-6">
                        {{getTitle(canvas.pattern,getIndex(canvas.pattern,11))}}
                    </p>
                    <b-icon
                        :pack="(canvas.pattern === 'bmc')?'fas':null"
                        :icon="getIcon(canvas.pattern,getIndex(canvas.pattern,11))"
                    ></b-icon>
                </div>
                <p v-if="canvas.pattern === 'lean'" class="has-text-left is-size-7 has-text-grey-light disablePrint">{{getDetail(canvas.pattern,11)}}</p>
                <Comment :canvas="canvas" @fetchCanvas="fetchCanvas" @statusSave="statusSave"  size="s"  @setLoadding="setLoadding" :index="getIndex(canvas.pattern,11)" :thumbnail="thumbnail"/>
            </div>
        </div>
    </div>
</template>

<script>
import Comment from './Comment'
export default {
    props:{
        thumbnail:{
            type:Boolean,
            default(){
                return false
            }
        },
        canvas:{
            type:Object,
            default(){
                return null
            }
        }
    },
    components:{
        Comment
    },
    methods: {
        statusSave(status){
            this.$emit('statusSave',status)
        },
        setLoadding(status){
            this.$emit('setLoadding',status)
        },
        fetchCanvas(){
            this.$emit('fetchCanvas')
        }
    },
}
</script>