<template>
  <div>
        <b-navbar class="nav-canvas disablePrint pd-1" type="is-primary">
        <template slot="brand">
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <img src="@/assets/logo-white.png" alt="inteGreat Canvas Tools" class="navLogo">
            </b-navbar-item>
        </template>

        <template slot="end">
            <b-navbar-item tag="div" :class="maxWidth > 1130 ? '' : 'is-flex flex-ai-center flex-js-center'">
             <!--  <a :href="url+'batch/view'" target="_blank" class="mg-x-4 has-text-white">
                <b-button type="is-white" class="mg-y-1" rounded outlined>Your project</b-button>
              </a> -->
              <b-dropdown
                v-if="Object.values(user).length > 0"                
                position="is-bottom-left"
                aria-role="menu">
                <template slot="trigger">
                    <p class="cr-pointer is-size-6 is-flex">
                      <span v-if="user.name !== '' &&  user.name !== null" class="pd-x-1">{{user.titleName}} {{user.name}} {{user.surname}}</span>
                      <span v-else class="pd-x-1">{{user.email}}</span>
                      <b-icon icon="sort-down" pack="fas" size="is-small"></b-icon>
                    </p>
                </template>
                <b-dropdown-item aria-role="menuitem" @click="isProfile = true">
                    <b-icon icon="user" pack="fas" ></b-icon>
                    Edit profile
                </b-dropdown-item>
                <b-dropdown-item aria-role="menuitem" @click="signout">
                 <!--  <a :href="url"> -->
                    <b-icon icon="sign-out-alt" pack="fas"></b-icon>
                    Sign out
                  <!-- </a> -->
                </b-dropdown-item>
            </b-dropdown>
            </b-navbar-item>
        </template>
    </b-navbar>

    <div class="page-main container is-fluid">

        <slot></slot>

    </div>
    <b-modal :active.sync="isProfile" :width="640">
      <Profile @closeProfileModal="closeProfileModal"/>
    </b-modal>
  </div>
</template>
<script>
import Profile from '@/components/auth/Profile'
export default {
  components:{
    Profile
  },
  computed: {
    user(){
      return this.$store.state.user
    }
  },
  data() {
    return {
      maxWidth: window.innerWidth,
      isProfile:false,
      url:process.env.VUE_APP_INTEGRAT_BASE_URL
    }
  },
  methods: {
    signout(){
      this.$store.commit('resetState')
      this.$router.push('/sign-in')
    },
    closeProfileModal(){
      this.isProfile = false
    }
  },
}
</script>

<style>
.navbar-brand > a:hover{
  background-color: transparent!important;
}
 .page-main{
    padding: 20px;
    min-height: 400px;
 }
 .nav-canvas, .navbar-menu.is-active{
    background-color: #0F4C82!important;
    color: #fff!important;
 }
 .navbar-item{
   color: #fff!important;
 }
 .navLogo{
    max-height: 4rem!important;
 }
</style>
