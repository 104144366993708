<template>
    <div v-if="canvas" >
        <CanvasNavigator v-if="canvas.pattern == 'navigator'" :canvas="canvas" :thumbnail="true"/>
        <CanvasLeanAndBmc v-else :thumbnail="true" :canvas="canvas"/>
    </div>
</template>
<script>
import CanvasNavigator from './CanvasNavigator'
import CanvasLeanAndBmc from './CanvasLeanAndBmc'
export default {
    components:{
        CanvasNavigator,
        CanvasLeanAndBmc
    },
    props:{
        canvas:{
            type:Object,
            default(){
                return null
            }
        }
    }
    
}
</script>
