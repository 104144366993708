

import Vue from 'vue'

const msgFailError = 'Failed. Please try again by signout or cache refresh.'
const msgCatchError = 'Failed. Please contact system administrator.'

Vue.mixin({
    methods: {
        alertFailError() {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: msgFailError,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        },
        alertCatchError() {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: msgCatchError,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        },
    },
  })
  