<template>
    <div class="modal-card" style="width: auto">
        <b-loading :active.sync="isLoadingPage"></b-loading>
        <header class="modal-card-head">
            <p class="modal-card-title has-text-left has-text-weight-normal">Edit profile</p>
        </header>

        <section class="modal-card-body has-text-left">
            <b-field grouped >
                <b-field 
                    label="Name" expanded 
                    :type="{'is-danger':errors.has('name')}"
                    :message="errors.has('name')?'กรุณาเลือกคำนำหน้าและกรอกชื่อ':''">
                        <b-input
                            class="is-medium"
                            type="text"
                            v-model="name"
                            placeholder="Name"
                            v-validate="'required'" 
                            name="name"
                            expanded
                        ></b-input>
                </b-field>
            </b-field>

            <b-field  
                label="Last name" 
                :type="{'is-danger':errors.has('lastName')}"
                :message="errors.has('lastName')?'กรุณากรอกนามสกุล':''">
                <b-input
                    class="is-medium"
                    type="text"
                    v-model="lastName"
                    placeholder="Last name"
                    v-validate="'required'" 
                    name="lastName"
                    expanded
                ></b-input>
            </b-field>
            <b-field  
                label="Telephone number" 
                :type="{'is-danger':errors.has('telephoneNumber')}"
                :message="errors.has('telephoneNumber')?'กรุณากรอกเบอร์โทร':''">
                <b-input
                    class="is-medium"
                    type="text"
                    v-model="telephoneNumber"
                    placeholder="Telephone number"
                    v-validate="'integer|min:9|required'" 
                    name="telephoneNumber"
                    expanded
                ></b-input>
            </b-field>
            <b-field  
                label="Company name" 
                :type="{'is-danger':errors.has('organizeName')}"
                :message="errors.has('organizeName')?'กรุณากรอกชื่อบริษัท':''">
                <b-input
                    class="is-medium"
                    type="text"
                    v-model="organizeName"
                    placeholder="Company name"
                    v-validate="'required'" 
                    name="organizeName"
                    expanded
                ></b-input>
            </b-field>
            <b-field  
                :type="{'is-danger':errors.has('position')}"
                :message="errors.has('position')?'กรุณากรอกตำแหน่ง':''"
                label="Position">
                <b-input
                    name="position"
                    class="is-medium"
                    type="text"
                    v-model="position"
                    v-validate="'required'" 
                    placeholder="Position"
                    expanded
                ></b-input>
            </b-field>
            <b-field  
                label="E-mail">
                <b-input
                    class="is-medium"
                    type="text"
                    disabled
                    v-model="email"
                    placeholder="E-mail"
                    expanded
                ></b-input>
            </b-field>
            

        </section>
        <footer class="modal-card-foot flex-js-end">
           
            <button class="button btn-min-w" @click="resetProfile(false)">Close</button> 
            <button class="button is-info btn-min-w" @click="editProfile">Save</button>
        </footer>
    </div>
</template>
<script>
// import {msgFailError} from '@/service/alertMsg'
import {HTTP} from '@/service/axios'

export default {
    props:['closeProfileModal'],
    computed: {
        prefix(){
            return this.$store.state.prefix
        },
        user(){
            return this.$store.state.user
        },
        token(){
            return this.$store.state.token
        }
    },
    data() {
        return {
            titleName:null,
            name:null,
            lastName:null,
            telephoneNumber:null,
            isLoadingPage:false,
            position:null,
            organizeName:null,
            email:null
        }
    },
    mounted() {
        this.setDataUser()
    },
    methods: {
        setDataUser(){
            if (Object.values(this.user).length > 0) {
                this.titleName=this.user.titleName
                this.name=this.user.name
                this.lastName=this.user.surname
                this.telephoneNumber=this.user.telephoneNumber 
                this.position=this.user.position 
                this.organizeName=this.user.organizeName 
                this.email=this.user.email 
            }
        },
        editProfile(){
            this.$validator.validate().then(valid => {
                if (valid) {
                    this.isLoadingPage = true
                    let obj={
                        titleName:this.titleName,
                        name:this.name,
                        surname:this.lastName,
                        telephoneNumber:this.telephoneNumber,
                        position:this.position,
                        organizeName:this.organizeName,
                        email:this.email 
                    }
                    HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
                    HTTP.post(`edit/profile`,obj)
                    .then(res => {
                        // console.log("edit/profile", res);  
                        if (res.data.success) {
                            this.$buefy.toast.open({
                                message:'Succeed!',
                                type: 'is-success'
                            })
                            this.resetProfile(true)

                            let user={
                                token:this.token
                            }
                            this.$store.dispatch("fetchUserDetail", user)
                            
                            this.isLoadingPage = false
                        }else{  
                            if (res.data.message == 'invalid authorization') {
                               this.expireToken()
                            }else{
                                this.isLoadingPage = false
                                this.alertFailError()
                            }
                        }
                    })
                    .catch(e => {
                        this.isLoadingPage = false
                        this.alertCatchError()
                        console.log("edit/profile/error",e);
                    });
                }
            })
           
        },
        async resetProfile(direct = false){
            await this.$emit('closeProfileModal',direct)
            this.titleName=null
            this.name=null
            this.lastName=null
            this.telephoneNumber = null
        },
    },
}
</script>
<style scoped>

</style>
