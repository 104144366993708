import { HTTP,HTTPTicket } from "@/service/axios";

export default {
  fetchUserDetail: async ({ commit }, obj) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
    HTTP.post(`get/user/detail`)
    .then(res => {
        if (res.data.success) {
          commit('setUser',res.data.obj)
        }else{
          if (res.data.message == 'invalid authorization') {
            commit('resetState')
          }else{
            commit('setUser',null)
          }
        }
    })
    .catch(e => {
        console.log("get/user/detail/error",e);
    });
  },
  fetchTicketPublic: async ({ commit }, obj) => {
    HTTPTicket.post(`api/get/ticket/public`,obj)
    .then(res => {
        if (res.data.success) {
           commit('setTicket',res.data.obj)
        }else{
          if (res.data.message == 'invalid authorization') {
            commit('resetState')
          }
        }
    })
    .catch(e => {
        console.log("api/get/ticket/public/error",e);
    });
  },
};
