import axios from 'axios';

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 1000 * 30,
});

export const HTTPTicket = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_TICKET,
  timeout: 1000 * 30,
});