import pattern from './constant/pattern'

const initState = () => ({
  page:'canvasList',
  canvas:null,
  canvasList:[],
  formAuth:null,
  user:{},
  batch:{},
  role:null,
  token:null,
  canvasOwned:{},
  prefix: [
    { id: 1, name: 'นาย' },
    { id: 2, name: 'นาง' },
    { id: 3, name: 'นางสาว' },
    { id: 4, name: 'ศาสตราจารย์' },
    { id: 5, name: 'ผู้ช่วยศาสตราจารย์' },
    { id: 6, name: 'รองศาสตราจารย์' },
    { id: 7, name: 'ดร.' },
  ],
  pattern:pattern,
  dragRemovedNote:null,
  dragAddNote:null,
  resetDrag:false,
  ticket:null,
  ticketToken:null
});

export default initState;
