<template>
    <div>
        <b-loading :active.sync="isLoadingPage"></b-loading>
        <div class="border-b pd-b-2 mg-b-5">
            <p class="is-size-4 has-text-weight-medium pd-t-4 has-text-left">Comments</p>
        </div>
        <div  class="columns  commentMassage">
            <!-- <div class="column is-1 ">
                <div class="imgComment">
                    <img src="@/assets/img/logo-integreat.png">
                </div>
            </div> -->
                <div class="column is-12 has-text-left">
                <p class="is-size-5 has-text-weight-medium pd-y-2">{{user.name}} {{user.surname}}</p>  
                <b-field label="" 
                    :type="{'is-danger':errors.has('message')}"
                    :message="errors.has('message')?'Please fill out Message':''">
                    <b-input 
                        v-model="massage"
                        v-validate="'required'"
                        type="textarea" 
                        rows="6"
                        name="message"
                    ></b-input>
                </b-field>
                <p class="is-size-6 has-text-weight-medium pd-y-1">Attachments: (Allowed File Extensions: .jpg, .gif, .jpeg, .png, doc, .docx, .xls, .xlsx, .pdf, .ppt, .pptx)</p>
                <div class="uploadBox">
                    <div  v-for="(item,index) in AttachmentsFile" :key="index"> 
                        <b-field class="file is-marginless pd-y-1">
                            <b-upload   @input="(file)=>uploadFile(file,index)" class=" ">
                                <b-field class="is-marginless ">
                                    <a class="button  btn-upload  uploadComment">
                                       
                                        <p v-if="item.name !== null" class="has-text-left w-100 ">{{item.name | upload}}</p>
                                        <p v-else class="has-text-left w-100 tc-detail">Choose a file...</p>
                                    </a>
                                    <p class="control">
                                        <a class="button is-light">Browse</a>
                                    </p> 
                                </b-field>
                            </b-upload>
                            <a v-if="index !== 0" @click="delFile(index)" class="control is-flex flex-ai-center mg-x-2">
                                <b-icon
                                    pack="fas"
                                    icon="minus-circle"
                                    type="is-danger"
                                    size="is-small">
                                </b-icon>
                            </a>
                        </b-field>
                        

                        <div v-if="item.status !== null" class="has-text-left Lh15">
                            <span v-if="item.status == 0" class="is-size-7 th has-text-danger">อัพโหลดไฟล์ล้มเหลว</span>
                            <span v-if="item.status == 1" class="is-size-7 th has-text-success">อัพโหลดไฟล์สำเร็จ</span>
                            <span v-if="item.status == 2" class="is-size-7 th">กำลังอัพโหลดไฟล์....</span>
                        </div>
                    </div>
                    <div class="pd-y-1 has-text-left-mobile">
                        <a class="is-size-6 " @click="addFileMore">
                            <b-icon
                                pack="fas"
                                icon="plus"
                                size="is-small">
                            </b-icon>
                            Add More
                        </a>
                    </div>
                </div>
                <div class="has-text-centered-mobile">
                    <div class="buttons">
			        		<button type="submit" class="bt-color-avocado is-rounded button is-info" rounded icon-left="send"  @click="postComment">Comment</button>
			        	</div>
          
                </div>
            </div>
        </div>
        <div v-if="ticket !== null">
            <div  v-for="(item,index) in comments" :key="index" class="columns  commentMassage">
                <!-- <div class="column is-1 ">
                    <div class="imgComment">
                        <img src="@/assets/img/logo-integreat.png">
                    </div>
                </div> -->
                <div class="column is-12 has-text-left">
                    <div class="titleBoxComment">
                        <p class="is-size-5 has-text-weight-medium">{{item.name}} {{item.surname}}</p>
                        <p class="is-size-7 tc-detail">Created date: {{item.createDate | dateTime}}</p>
                    </div>
                    <div class="mg-t-2">
                        <p class="is-size-6">{{item.message}}</p>
                    </div>
                    <div v-if="item.commentFile.length > 0" class="mg-y-2">
                        <p class="is-size-6 has-text-weight-medium ">Attachments</p>
                        <div v-for="file in item.commentFile" :key="file._id" >
                            <a target="_bank" :href="pathComment+file.fileName" class="is-size-6 textFile pd-l-2 wb-ba"> 
                                {{file.fileName}} 
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="commentsConst.length > 10 && limit <= comments.length">
                <a @click="viewMore" class="text-viewmore">View more</a>
            </div>
        </div>
    </div>
</template>
<script>
import {HTTPTicket} from '@/service/axios'
export default {
    name:'CommentPost',
    data() {
        return {
            massage:null,
            AttachmentsFile:[
                {name:null,status:null}
            ],
            pathComment:process.env.VUE_APP_BASEURL_TICKET_FILE_COMMENT,
            isLoadingPage:false,
            limit:10,
            canvasId:''
        }
    },
    destroyed() {
        this.$store.commit('setTicket',null)
        this.$store.commit('setTicketToken',null)
    },
    computed: {
        ticket(){
            return this.$store.getters.getTicket
        },
        comments(){
            return this.$store.getters.getTicket.comments.slice(0, this.limit)
        },
        commentsConst(){
            return this.$store.getters.getTicket.comments
        },
        ticketToken(){
            return this.$store.state.ticketToken
        },
        user(){
            return this.$store.state.user
        },
        canvas(){
            return this.$store.state.canvas 
        }
    },
    mounted() {
        if (typeof this.$route.query.id != 'undefined') {
            this.canvasId = this.$route.query.id;
        }
    },
    methods: {
        viewMore(){
            this.limit = this.limit + 10
        },
        addFileMore(){
            this.AttachmentsFile.push({name:null,status:null})
        },
        delFile(indexDel){
            this.AttachmentsFile = this.AttachmentsFile.filter((filter,index)=>index !== indexDel)
        },
        async postComment(){
            this.$validator.validate().then(async(valid) => {
                if (valid) {
                    this.isLoadingPage = true
                    let file = []
                    this.AttachmentsFile.map((item)=>{
                        if (item.status === 1) {
                            file.push({fileName:item.name})
                        }
                    })
                    let obj = {
                        ticketId:(this.ticket !== null)?this.ticket._id:'',
                        comment:{
                            message:this.massage,
                            userLevel:0,
                            score:0,
                            commentFile:file
                        },
                        status:3
                    }

                    HTTPTicket.defaults.headers.common.Authorization = `Bearer ${this.ticketToken}`;
                    await HTTPTicket.post(`api/add/commment`,obj).then(res => {
                        if (res.data.success) {
                            this.getTicketPublic()
                            this.massage = null
                            this.AttachmentsFile = [{name:null,status:null}]
                            this.$validator.reset()
                        }else{
                            if (res.data.message == 'invalid authorization') {
                                this.expireToken()
                            }else{
                                this.alertFailError()
                            }
                        }
                        this.isLoadingPage = false
                    }).catch(e => {  
                        this.alertCatchError()
                        this.isLoadingPage = false
                        console.log("api/add/commment/error",e);
                    });
                }
            })    

        },
        async uploadFile(file,index){
            if (file !== null) {
                this.AttachmentsFile[index].status = 2 
            }

            let formData = new FormData();
            formData.append("file", file);

            HTTPTicket.defaults.headers.common.Authorization = `Bearer ${this.ticketToken}`;
            await HTTPTicket.post(`/upload/comment`,formData).then(res => {
                if (res.data.success) {
                    this.AttachmentsFile[index].name = res.data.obj
                    this.AttachmentsFile[index].status = 1
                }else{
                    if (res.data.message == 'invalid authorization') {
                        this.expireToken()
                    }else{
                        this.AttachmentsFile[index].name =null
                        this.AttachmentsFile[index].status = 0
                    }
                }
            }).catch(e => {  
                this.AttachmentsFile[index].status = 0
                console.log("/upload/comment/error",e);
            });
        },
        getTicketPublic(){
            let obj={
                email:this.user.email,
                roleId:this.canvasId,
                codePublic:this.ticket.codePublic
            }
            this.$store.dispatch('fetchTicketPublic',obj)
        },
    },
    
}
</script>
<style scoped>
@media only screen and (max-width: 768px) {
    .uploadBox{
        width: 100%!important;
        text-align: center!important;
    }
    .uploadComment input[type=file]{
        width: 150px!important;
        z-index: 10!important;
    }
    .uploadComment{
        width: 150px!important;
    }
}
@media only screen and (min-width: 768px) {
    .uploadBox{
        width: 600px!important;
        text-align: center!important;
    }
    .uploadComment input[type=file]{
        width: 600px!important;
        z-index: 10!important;
    }
    .uploadComment{
        width: 600px!important;
        /* background-color: red; */
    }
}
.commentMassage{
   border: 1px solid #c4c4c4;
   border-radius: 5px;
   margin:10px 0 10px 0;
   min-height: 200px;
   padding: 10px;
   background-color: white;
}
.imgComment{
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    background-color: #f8f9fa!important;
    box-shadow: 0 2px 8px 0 #c4c4c4;
}
.titleBoxComment{
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 1rem;
}
.tagFile{
    display: flex;
    flex-wrap: wrap;
}
.Lh15{
    line-height: 15px;
}
.textFile{
    color:#7d7d7d;
    cursor: pointer;
}
.textFile:hover{
    color:#167df0;
}
</style>
