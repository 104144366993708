<template>
  <div id="app" :class="$route.name == 'sign in' || $route.name == 'sign up' || $route.name == 'forgot password' || $route.name == 'reset password' ? 'bg-color-primary':''">
    <router-view :key="$route.fullPath"/>
  </div>
</template>

<script>

export default {
  name: 'app',
  sockets: {
      connect() {
          // console.log('socket connected')
      },
  },
}
</script>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: #0F4C82;
$primary-invert: findColorInvert($primary);
$twitter: #4099FF;
$twitter-invert: findColorInvert($twitter);
$color-Blue:#0080E0;
// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
    "white": ($white, $black),
    "black": ($black, $white),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
    "primary": ($primary, $primary-invert),
    "info": ($info, $info-invert),
    "success": ($success, $success-invert),
    "warning": ($warning, $warning-invert),
    "danger": ($danger, $danger-invert),
    "twitter": ($twitter, $twitter-invert)
);

// Links
$link: $color-Blue;
$link-invert: $primary-invert;
$link-focus-border: $primary;
$avocado-color-primary:#0F4C82;
$button-color-primary:#0F4C82;
$color-danger: #FF6262;
$color-orange: #F47521;
// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

@page {
  size: A4 landscape;
  margin:0 5mm;
  padding:  10mm;
}
@media print {
  #app{
    background-color: white!important;
    padding-bottom: 0!important;
  }
  .license{
    display: flex!important;
  }
  
  header,.disablePrint,hr  {
    display: none !important;
  }
  .page-main{
    padding: 10px!important;
  }
  .canvas{
    min-height: 180mm!important;
    min-width: 276mm!important;
  }
  .font-print{
    font-size: 13px!important;
  }
  .showOnlyPrint{
    display: block!important;
  }
  .font-print-comment{
    font-size: 7px!important;
  }
  .box-canvas-xi{
    overflow-y: unset!important;
    overflow-x: unset!important;
    min-height: 380px!important;
    max-height: unset!important;
  }
  .box-canvas-l{
    overflow-y: unset!important;
    overflow-x: unset!important;
    min-height: 160px;
    max-height: unset!important;
  }
  .box-canvas-m{
    overflow-y: unset!important;
    overflow-x: unset!important;
    min-height: 100px!important;
    max-height: unset!important;
  }
  .box-canvas-s{
    overflow-y: unset!important;
    overflow-x: unset!important;
    min-height: 50px!important;
    max-height: unset!important;
  }
  .dsBox{
    height: auto!important;
  }
  .over-x-auto{
    overflow: unset!important;
  }
}

.box-canvas-xi{
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 480px;
}
.box-canvas-l{
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 260px;
}
.box-canvas-m{
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px;
}
.box-canvas-s{
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 150px;
}

.decoration-none{
  text-decoration: none!important;
}

.text-black{
  color: #2B2B2B!important;
}
.showOnlyPrint{
  display: none;
}

.fix-btn-primary{
  border: 1px solid $color-orange;
  background-color: $color-orange;
  padding: 8px 15px;
  border-radius: 5px;
  color: #fff;
}
.fix-btn-primary:hover{
  border: 1px solid #e46008;
  background-color: #e46008;
  color: #fff;
}


.hover-primary:hover{
  color:$primary!important;
}

.moving-card {
  opacity: 0.5!important;
  border: 1px dashed #4299e1 !important;
}

.moving-card *{
  color:rgb(242, 252, 255) !important;
  background: rgb(242, 252, 255) !important;
}

.moving-card > div{
  visibility: hidden!important;
}

.chosen-card {
  cursor : -webkit-grabbing!important;
  cursor : -moz-grabbing!important;
  cursor : -o-grabbing!important;
  cursor : grabbing!important;
  // background: rgb(242, 252, 255) !important;
  border: 1px solid #e6e8ec;
}

.chosen-card:active {
  cursor : pointer!important;
}

.dragging-card{
  opacity: 0.9 !important;
  // // transition: transform 0.18s ease;
  transform: rotateZ(1.25deg);
  -webkit-box-shadow: 6px 10px 10px -3px rgba(0,0,0,0.2);
  -moz-box-shadow: 6px 10px 10px -3px rgba(0,0,0,0.2);
  box-shadow: 6px 10px 10px -3px rgba(0,0,0,0.2);
}
.control.has-icons-right .icon.is-right{
  right:10px;
  color:$color-Blue!important;
}

.bg-color-primary{
  background-color:$primary!important;
}

.bt-color-avocado{
  background-color:$primary!important;
}

.text-color-primary{
  color:$primary;
}

.boxAddCanvas{
    border: 1px dashed $primary;
    color: $primary;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-height: 220px;
    height: 100%;
}
.boxAddCanvas:hover{
    border: 1px dashed $primary;
    color: $primary;
}

.text-over {
  white-space: nowrap;
  overflow: hidden;
  max-width: 80vw;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
  padding-right: 0.15em;
}


#app {
  font-family: "Mitr", sans-serif!important;
  font-weight: 300!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2B2B2B;
  background-color: #f5f5f5;
  min-height: 100vh;
}

body, button, input, select, textarea{
  font-family: "Mitr", sans-serif!important;
  font-weight: 300!important;
}

.text-color-title{
  color:#7a7a7a;
}

.text-grey-lighter{
  color: #c6c6c6;
}

.text-green{
  color: #00A79D;
}

/* .input:focus, .taginput .taginput-container.is-focusable:focus, .textarea:focus, .select select:focus, .is-focused.input, .taginput .is-focused.taginput-container.is-focusable, .is-focused.textarea, .select select.is-focused, .input:active, .taginput .taginput-container.is-focusable:active, .textarea:active, .select select:active, .is-active.input, .taginput .is-active.taginput-container.is-focusable, .is-active.textarea, .select select.is-active {
    border-color: #167df0!important;
    -webkit-box-shadow: 0 0 0 0.125em rgba(22, 125, 240, 0.25)!important;
    box-shadow: 0 0 0 0.125em rgba(22, 125, 240, 0.25)!important;
} */
.button:focus.btn-info{
  border-color: $primary!important;
  -webkit-box-shadow: 0 0 0 0.125em !important;
  box-shadow: 0 0 0 0.125em rgba(15,76,130, 0.25)!important;
}
.mh-auto{
  min-height: auto!important;
}
.flex-dc{
  flex-direction: column!important;
}
.flex-wrap {
  flex-wrap: wrap!important;
}
.flex-js-center{
  justify-content: center!important;
}
.flex-js-between{
  justify-content: space-between!important;
}
.flex-js-end{
  justify-content: flex-end!important;
}
.flex-ai-center{
  align-items: center!important;
}
.flex-ai-start{
  align-items: flex-start!important;
}
.flex-ai-end{
  align-items: flex-end!important;
}
.w-100{
  width: 100%!important;
}
.mg-1{
  margin: .25rem!important;
}
.mg-5{
  margin: .5rem!important;
}
.mg-t-1{
  margin-top: .25rem!important;
}
.mg-t-2{
  margin-top: .5rem!important;
}
.mg-t-3{
  margin-top: .75rem!important;
}
.mg-t-4{
  margin-top: 1rem!important;
}
.mg-t-5{
  margin-top: 1.25rem!important;
}
.mg-l-1{
  margin-left: .25rem!important;
}
.mg-l-2{
  margin-left: .5rem!important;
}
.mg-l-3{
  margin-left: .75rem!important;
}
.mg-l-4{
  margin-left: 1rem!important;
}
.mg-l-5{
  margin-left: 1.25rem!important;
}
.mg-b-1{
  margin-bottom: .25rem!important;
}
.mg-b-2{
  margin-bottom: .5rem!important;
}
.mg-b-3{
  margin-bottom: .75rem!important;
}
.mg-b-4{
  margin-bottom: 1rem!important;
}  
.mg-b-5{
  margin-bottom: 1.25rem!important;
}
.mg-x-auto{
  margin-left: auto!important;
  margin-right: auto!important;
}

.mg-x-1{
  margin-left: .25rem!important;
  margin-right: .25rem!important;
}
.mg-x-2{
  margin-left: .5rem!important;
  margin-right: .5rem!important;
}
.mg-x-3{
  margin-left: .75rem!important;
  margin-right: .75rem!important;
}
.mg-x-4{
  margin-left: 1rem!important;
  margin-right: 1rem!important;
}
.mg-x-5{
  margin-left: 1.25rem!important;
  margin-right: 1.25rem!important;
}
.mg-y-1{
  margin-top: .25rem!important;
  margin-bottom: .25rem!important;
}
.mg-y-2{
  margin-top: .5rem!important;
  margin-bottom: .5rem!important;
}
.mg-y-3{
  margin-top: .75rem!important;
  margin-bottom: .75rem!important;
}
.mg-y-4{
  margin-top: 1rem!important;
  margin-bottom: 1rem!important;
}
.mg-y-5{
  margin-top: 1.25rem!important;
  margin-bottom: 1.25rem!important;
}
.pd-1{
  padding: .25rem!important;
}
.pd-r-2{
  padding-right: .5rem!important;
}
.pd-2{
  padding: .5rem!important;
}
.pd-3{
  padding: .75rem!important;
}
.pd-4{
  padding: 1rem!important;
}
.pd-5{
  padding: 1.25rem!important;
}
.pd-l-1{
  padding-left: .25rem!important;
}
.pd-l-2{
  padding-left: .5rem!important;
}
.pd-l-3{
  padding-left: .75rem!important;
}
.pd-l-4{
  padding-left: 1rem!important;
}
.pd-l-5{
  padding-left: 1.25rem!important;
}
.pd-y-0{
  padding-top:0!important;
  padding-bottom: 0!important;
}
.pd-y-1{
  padding-top: .25rem!important;
  padding-bottom: .25rem!important;
}
.pd-y-2{
  padding-top: .5rem!important;
  padding-bottom: .5rem!important;
}
.pd-y-3{
  padding-top: .75rem!important;
  padding-bottom: .75rem!important;
}
.pd-y-4{
  padding-top: 1rem!important;
  padding-bottom: 1rem!important;
}
.pd-y-5{
  padding-top: 1.25rem!important;
  padding-bottom: 1.25rem!important;
}
.pd-x-1{
  padding-left: .25rem!important;
  padding-right: .25rem!important;
}
.pd-x-2{
  padding-left: .5rem!important;
  padding-right: .5rem!important;
}
.pd-t-1{
  padding-top: .25rem!important;
}
.pd-t-2{
  padding-top: .5rem!important;
}
.pd-t-3{
  padding-top: .75rem!important;
}
.pd-t-4{
  padding-top: 1rem!important;
}
.pd-t-5{
  padding-top: 1.25rem!important;
}

.pd-t-6{
  padding-top: 2rem!important;
}

.pd-b-less{
  padding-bottom: 0!important;
}

.pd-b-1{
  padding-bottom: .25rem!important;
}
.pd-b-2{
  padding-bottom: .5rem!important;
}
.pd-b-3{
  padding-bottom: .75rem!important;
}
.pd-b-4{
  padding-bottom: 1rem!important;
}
.pd-b-5{
  padding-bottom: 1.25rem!important;
}
.over-x-auto{
    overflow: auto
}
.cr-pointer{
  cursor: pointer!important;
}
.position-abs{
  position: absolute!important;
}
.position-rt{
  position: relative!important;
}

.btn-back{
  color: #2c3e50!important;
}
.btn-back:hover{
  color: #167df0!important;
}
.btn-gray{
  color: gray!important;
  background-color: transparent!important;
  border:1px solid #dbdbdb!important;
}
.btn-gray:hover{
  color: #2c3e50!important;
}
.btn-danger{
  color: gray!important;
  background-color: transparent!important;
  border:1px solid #dbdbdb!important;
}
.btn-danger:hover{
  color: $color-danger!important;
  border:1px solid $color-danger!important;
}
.btn-under{
  color: #167df0!important;
}
.btn-under:hover{
  text-decoration: underline!important;
}
.btn-min-w{
  min-width: 120px
}
.db-filter .dropdown-menu .dropdown-content{
  padding: 15px;
  text-align: left;
}
.modal-search {
  width: 380px !important;
}
@media only screen and (max-width: 768px) {
  .modal-search {
    width: 100% !important;
  }
}
.db-filter .dropdown-menu .dropdown-content .dropdown-item:focus{
 border:0px
}
ul{
  list-style:inside!important;
}
.box-canvas-xi::-webkit-scrollbar,
.box-canvas-l::-webkit-scrollbar,
.box-canvas-m::-webkit-scrollbar,
.box-canvas-s::-webkit-scrollbar,
.historyBox::-webkit-scrollbar{
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}
.box-canvas-xi::-webkit-scrollbar-track,
.box-canvas-l::-webkit-scrollbar-track,
.box-canvas-m::-webkit-scrollbar-track,
.box-canvas-s::-webkit-scrollbar-track,
.historyBox::-webkit-scrollbar-track  {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
}
.box-canvas-xi::-webkit-scrollbar-thumb,
.box-canvas-l::-webkit-scrollbar-thumb,
.box-canvas-m::-webkit-scrollbar-thumb,
.box-canvas-s::-webkit-scrollbar-thumb,
.historyBox::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #dbdbdb;
  border: 1px solid #dbdbdb;
  transition: all 0.3 ease-in-out;
}
.box-canvas-xi::-webkit-scrollbar-thumb:hover,
.box-canvas-l::-webkit-scrollbar-thumb:hover,
.box-canvas-m::-webkit-scrollbar-thumb:hover,
.box-canvas-s::-webkit-scrollbar-thumb:hover,
.historyBox::-webkit-scrollbar-thumb:hover{
  background: #b5b5b5;
}
.border-t{
  border-top: 1px solid #dbdbdb; 
}
.border-b{
  border-bottom: 1px solid #dbdbdb; 
}
.dialog .modal-card.animation-content .modal-card-body.is-flex,.modal-card-foot .button{
  word-break: break-all!important;
  font-family: "Mitr", sans-serif!important;
  font-weight: 300!important;
}
.dialog .modal-card.animation-content .modal-card-head{
  word-break: break-all!important;
  font-family: "Mitr", sans-serif!important;
  font-weight: 400!important;
}
b{
  font-weight: 500!important;
}
.input, .taginput .taginput-container.is-focusable, .textarea, select{
  font-family: "Mitr", sans-serif!important;
  font-weight: 300!important;
}
.label {
  font-weight: 400!important;
}
.index-layer-1{
  z-index: 10;
}
.index-layer-2{
  z-index: 20;
}
.vs-hidden{
  visibility: hidden!important;
}
.vc-compact{
    padding: 0!important;
    width: 286px!important;
}
.vc-compact-color-item{
    margin: 4px!important;
}
.vc-compact-colors{
  padding: 5px!important;
}
.textareaColor .textarea{
    color:unset!important;
}
.vc-compact-color-item--white{
    background: #f2f2f2!important;
}
.btn-color{
      color: #167df0!important;
}
.btn-color:hover{
      color: #363636!important;
}
.tc-detail{
  color: #7d7d7d!important;
}
.tc-add{
  color: #167df0!important
}
.tc-add:hover{
  color: #2c3e50 !important;
}
.text-viewmore{
  color: #7d7d7d!important;
}
.text-viewmore:hover{
  color: #167df0!important;
}
.over-hidden{
  overflow: hidden;
}
.wb-ba{
  word-break:break-all!important;
}
.btn-upload{
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
}
.min-h-modal{
  min-height: 100px!important;
}
.modal.is-active{
  z-index: 999999!important;
}

.bd-all{
    border: 1.5px solid black;
}
.bd-bt{
    border-bottom: 1.5px solid black;
}
.bd-r{
    border-right: 1.5px solid black;
}
.flex-xl{
    flex:2
}
.flex-l{
    flex:1.5
}
.flex-m{
    flex:1
}
.flex-s{
    flex:0.5
}
.license{
    position: absolute;
    opacity: 0.1;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
}
.canvas-detail{
    min-height: 80vh;
    min-width: 1100px;
    background-color: white;
    position: relative;
}

.rounded{
  border-radius:5px;
}



@media screen and (max-width: 768px){
  .modal .animation-content {
      width: 90%;
  }
}

.color-gray{
  color: gray!important;
}
</style>
