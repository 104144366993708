

import Vue from 'vue'

Vue.mixin({
    methods: {
        expireToken() {
            this.$store.commit('resetState')
            // window.location.href(process.env.VUE_APP_INTEGRAT_BASE_URL)
            window.close();
        },
    },
  })
  