<template>
    <div>
        <CanvasManage size="canvasFull"/>
        <CommentPost class="disablePrint"/>
    </div>
</template>
<script>
import CanvasManage from './CanvasManage'
import CommentPost from './CommentPost'
export default {
    name:'CanvasDetail',
    components:{
        CanvasManage,
        CommentPost
    },
    data(){
        return {

        }
    },
    computed: {
        formAuth(){
            return this.$store.state.formAuth
        }
    },
    methods: {

    },
    
}
</script>
<style scoped>

</style>
