<template>
    <div>
        <ul class="has-text-left " >
            <div :class="[getClassBoxCanvas(size),(thumbnail === true)?getThumnailSize(size):null]">
                <draggable 
                    v-if="model !== null" 
                    v-model="model.note"
                    group="card"
                    :disabled="role==0"
                    ghost-class="moving-card"
                    drag-class="dragging-card"
                    chosen-class="chosen-card"
                    :force-fallback="true"
                    :swapThreshold="1"
                    :animation="200"
                    :delay-on-touch-only="true"
                    :delay="200"
                    :touchStartThreshold="300"
                    @change="changeNote">
                    <!-- <transition-group name="no" class="list-group" tag="ul"> -->
                    <div v-for="item in model.note" :key="item._id" :title="item.title" class="listGroupBox">
                        <div class="position-rt disablePrint">
                            <div
                                class="listGroupItem text-black" 
                                :class="[
                                (getHeight(size,model.note.length) === '20px')?'is-size-7 pd-y-0':'is-size-6',
                                {'dsBox':thumbnail === false}]"
                                :style="{
                                    'background-color':getColorCard(item.color.hex),
                                    'height':getHeight(size,model.note.length),
                                    '-webkit-line-clamp': getHeight(size,model.note.length) === '80px' ? 3 : getHeight(size,model.note.length) === '55px' ? 2 : 1
                                    }"><span 
                                    @tap="()=>(role === 1)?openmodalAddComment(item):[message = item.title,isViewComment = true]"
                                    @click="()=>(role === 1)?openmodalAddComment(item):[message = item.title,isViewComment = true]" 
                                    class="editTrigger"></span>{{item.title}}</div>
      
                            <span v-if="role===1" class="listDelBox " @click="confirmDeleteCanvas(item._id,item.title)">x</span>
                        </div>

                        <div class="showOnlyPrint">
                            <div
                                class="listGroupItem text-black font-print-comment" 
                                :style="{'background-color':getColorCard(item.color.hex) }"><span></span>{{item.title}}</div>
                        </div>
                        
                    </div>

                </draggable>
            </div>
            <div v-show="role === 1" class="pd-2 has-text-centered disablePrint">
                <b-button 
                    @click="()=>[modalType = 'add',isAddComment = true]"
                    type="is-light" 
                    icon-left="plus" 
                    icon-pack="fas" 
                    size="is-small" 
                    rounded>
                    Card
                </b-button>
            </div>
        </ul>

        <b-modal :active.sync="isAddComment" :width="640" @close="resetCanvasModal">
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title has-text-left has-text-weight-normal">{{(modalType === 'add')?'Add card':'Edit card'}}</p>
                    <a @click="confirmDeleteCanvas(noteId,message)"  v-show="role === 1 && modalType !== 'add'" class="mg-x-1 disablePrint">
                        <b-icon
                        pack="fas"
                        icon="trash"  
                        type="is-danger"
                        class="delIcon"
                        ></b-icon>
                    </a>
                </header>
                <section class="modal-card-body has-text-left">
                    <div class="has-text-right ">
                        <div class="is-flex flex-js-end flex-ai-center">
                            <a v-for="(item,index) in colorList" :key="index" @click="updateValue(item)" class="pd-x-1">
                                <b-icon
                                    v-show="item.hex === colors.hex"
                                    icon="check"
                                    class="selectColor"
                                    custom-size="mdi-18px"
                                ></b-icon>
                                <b-icon
                                    pack="fas"
                                    icon="circle"
                                    class="colorIcon"
                                    :style="{color:item.hex}"
                                ></b-icon>
                            </a>
                        </div>
                    </div>
                    <b-field 
                        class="pd-y-2" 
                        label="" 
                        :type="{'is-danger':errors.has('message')}"
                        :message="errors.has('message')?'กรุณากรอกข้อความ':''">
                        <b-input 
                            v-model="message"
                            v-validate="'required'" 
                            name="message"
                            class="textareaColor"
                            type="textarea">
                        </b-input>
                    </b-field>
                </section>
                <footer class="modal-card-foot flex-js-end">                   
                    <button class="button btn-min-w"  @click="resetCanvasModal">Close</button>
                    <button class="button btn-min-w is-success" @click="(modalType === 'add')?addComment(model,message,colors):editComment()">Save</button>
                </footer>
            </div>
        </b-modal>
        
        <b-modal :active.sync="isViewComment" width="90%" @close="[isViewComment = false,message='']">
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title has-text-left has-text-weight-normal">Card's detail</p> 
                </header>
                <section class="modal-card-body has-text-left">
                    <div class="min-h-modal">
                        <p class="th is-size-6">{{message}}</p>
                    </div> 
                </section>
                 <footer class="modal-card-foot flex-js-end">                   
                    <button class="button btn-min-w"  @click="[isViewComment = false,message='']">Close</button>
                </footer>
            </div>
        </b-modal>
    </div>
</template>
<script>
const  color = {
            hex: '#d8f4bc',
        }
import {HTTP} from '@/service/axios'
// import {msgFailError} from '@/service/alertMsg'
import draggable from "vuedraggable";

export default {
    name:'Comment',
    props:{
        size:{
            default: 'm',
            type: String 
        },
        index:{
            default:null,
            type: Number 
        },
        thumbnail:{
            default:false,
            type: Boolean 
        },
        canvas:{
            type:Object,
            default(){
                return null
            }
        }
        
    },
    components:{
        draggable
    },
    data(){
        return {
            isAddComment:false,
            isViewComment:false,
            message:null,
            noteId:null,
            colors: color,
            colorList:[
              {
                hex: '#b3f6fe',
              },               {
                hex: '#f4bce2',
              },               {
                hex: '#bcf4db',
              },               {
                hex: '#f4f1bc',
              },               {
                hex: '#d8f4bc',
              },               {
                hex: '#dabcf4',
              },
              {
                hex: '#f4bcbc',
              }  
            ],
            model:[],
            modalType:null,
            noteOld:[],
            isDragging:false
        }
    },
    watch: {
        resetDrag(){
            
            if (this.resetDrag === true) {
                this.fetchCanvas()
                setTimeout(() => {
                    this.$store.commit('setResetDrag',false)
                }, 200);
            }
        }
    },
    computed: {
        role(){
            return this.$store.state.role
        },
        dragRemovedNote(){
            return this.$store.state.dragRemovedNote
        },
        dragAddNote(){
            return this.$store.state.dragAddNote
        },
        resetDrag(){
            return this.$store.state.resetDrag
        }
    },
    sockets: {
        resGetCanvas(res){
            console.og('resGetCanvas',res)
            // if (res.success && res.obj._id == this.canvas._id) {   
            //     let canvas = res.obj
            //     canvas['pattern'] = this.canvas.pattern
            //     this.$store.commit('setCanvas',canvas)
            //     this.getModelIndex()
            // }
        }
    },
    mounted() {
        // this.$emit('setLoadding',true)
        this.getModelIndex()
        this.fetchCanvas()
    },
    methods: {
        getHeight(size,note){
            if (size === 'xl') {
                let heigth = '30px'
                if (note >= 10) {
                    heigth = '20px' 
                }else if (note >= 8) {
                    heigth = '30px' 
                }else if (note < 8){
                    heigth = '80px'
                }else{
                    heigth = '30px'  
                }
               return heigth
            }else if (size === 'l') {
                let heigth = '30px'
                if (note >= 7) {
                    heigth = '20px' 
                }else if (note >= 5) {
                    heigth = '30px' 
                }else if (note < 5){
                    heigth = '80px'
                }else{
                    heigth = '30px'  
                }
               return heigth
            }else if (size === 'm'){
                let heigth = '30px'
                if (note >= 6) {
                    heigth = '20px' 
                }else if (note >= 4) {
                    heigth = '30px' 
                }else if (note < 4){
                    heigth = '55px'
                }else{
                    heigth = '30px'  
                }
               return heigth
            }else if (size === 's'){
                let heigth = '30px'
                if (note >= 5) {
                    heigth = '20px' 
                }else if (note >= 3) {
                    heigth = '30px' 
                }else if (note < 3){
                    heigth = '80px'
                }else{
                    heigth = '30px'  
                }
                return heigth
            }
        },
        async changeNote({added,removed,moved}){
            this.$emit('statusSave',1)
            if (typeof moved !== 'undefined') {  
                let note = []
                this.model.note.map((item,index)=>{
                    note.push({noteId:item._id,index:index})
                })

                this.updateIndexNote(note) 
            }else if (typeof added !== 'undefined') {
                let obj ={
                    addTitle:added.element.title,
                    addColor:added.element.color,
                    model:this.model 
                }
                this.$store.commit('setDragAddNote',obj)
                this.$buefy.dialog.confirm({
                    title: 'Move card',
                    message: `Are you sure you want to move <b>${added.element.title}</b> to another category?`,
                    cancelText: 'No',
                    confirmText: 'Yes',
                    type: 'is-success',
                    onCancel:()=>[
                        this.$store.commit('setDragRemovedNote',null),
                        this.$store.commit('setDragAddNote',null),
                        this.$store.commit('setResetDrag',true)],
                    onConfirm: () => this.moveCard()
                })
            }else if (typeof removed !== 'undefined'){
                let obj ={
                   removedId:removed.element._id,
                   note:this.model.note 
                }
                this.$socket.emit('getCanvas',{canvasId:this.canvas._id});
                this.$store.commit('setDragRemovedNote',obj)
                this.$socket.emit('getCanvas',{canvasId:this.canvas._id});
            }
        },
        async moveCard(){
            if (this.dragAddNote !== null && this.dragRemovedNote !== null) {
                this.dragAddNote.model.note.map((item,index)=>{
                this.noteOld.push({noteId:item._id,index:index,title:item.title})
                })
                await this.addComment(this.dragAddNote.model,this.dragAddNote.addTitle,this.dragAddNote.addColor,false)
                await this.delComment(this.dragRemovedNote.removedId,false)
                let note = []
                this.dragRemovedNote.note.map((item,index)=>{
                    note.push({noteId:item._id,index:index})
                })
                await this.updateIndexNote(note)  
                await this.$store.commit('setDragAddNote',null)
                await this.$store.commit('setDragRemovedNote',null)
                this.$socket.emit('getCanvas',{canvasId:this.canvas._id});
                // setTimeout(()=>{
                //     this.$emit('setLoadding',false)
                // },500)
            }
        },
        fetchCanvas(loading = false){
            if (loading === true) { 
              this.$emit('setLoadding',true)
            }

            this.$emit('fetchCanvas',status)
            setTimeout(() => {
                this.getModelIndex()
            }, 1000);
        },
        getModelIndex(){
            let model = this.canvas.model.filter(item=>item.index===this.index)
            if (model.length > 0) {
                if (this.noteOld.length > 0) {
                    let note =[]
                    model[0].note.map((item)=>{
                        // this.noteOld.map((itemOld,index)=>{
                        //     if (item._id === itemOld._id) {
                               
                        //     }
                        // })
                        let filter = this.noteOld.filter(filter => filter._id === item._id)
                        if (filter.length === 1) {
                            note.push({noteId:item._id,index:filter[0].index})
                        }else{
                            let filterTitle= this.noteOld.filter(filter => filter.title === item.title)
                            if (filterTitle.length === 1) {
                                note.push({noteId:item._id,index:filterTitle[0].index})
                            }
                        }
                    })
                    
                    this.updateIndexNote(note) 
                    this.noteOld = []
                }else{
                    let sort =   model[0].note.sort((a, b) => {
                        let modifier = 1;
                        if (typeof a.index !== 'undefined' && typeof b.index !== 'undefined') {
                            if (a.index < b.index) return -1 * modifier;
                            if (a.index > b.index) return 1 * modifier;  
                        }
                        return 0;
                    });
                   
                    this.model =  {_id:model[0]._id,index:model[0].index,name:model[0].name,note:sort}

                    setTimeout(()=>{
                        this.$emit('setLoadding',false)
                    },500) 
                }
            }else{
                this.model = null
                setTimeout(()=>{
                    this.$emit('setLoadding',false)
                },500) 
            }
        },
        confirmDeleteCanvas(id,title) {
            this.$buefy.dialog.confirm({
                title: `Deleting text`,
                message: `Are you sure you want to delete <b>${title}?</b> This action cannot be undone.`,
                confirmText: `Delete text`,
                type: 'is-danger',
                hasIcon: true,
                iconPack:'fas',
                onConfirm: () => this.delComment(id)
            })
        },
        getClassBoxCanvas(name){
            let className = ''
            switch (name) {
                case 'xl':
                    className = 'box-canvas-xi'
                    break;
                case 'l':
                    className = 'box-canvas-l'
                    break;
                case 'm':
                    className = 'box-canvas-m'
                    break;
                case 's':
                    className = 'box-canvas-s'
                    break;
                default:
                    break;
            }
            return className
        },
        getThumnailSize(name){
            let className = ''
            switch (name) {
                case 'xl':
                    className = 'thumbnailXl'
                    break;
                case 'l':
                    className = 'thumbnailL'
                    break;
                case 'm':
                    className = 'thumbnailM'
                    break;
                case 's':
                    className = 'thumbnailS'
                    break;
                default:
                    break;
            }
            return className
        },
        openmodalAddComment(item){
            this.modalType = 'edit'
            this.message = item.title
            this.colors = item.color
            this.noteId=item._id
            this.isAddComment = true 
        },
        resetCanvasModal(){
            this.isAddComment = false
            setTimeout(() => {
                this.message = null
                this.colors = color
                this.noteId= null
            }, 100);
        },
        updateValue(value){

            if (typeof value.hex !== 'undefined' && value.hex === '#FFFFFF') {
               this.colors = {
                    a: 1,
                    hex: '#F2F2F2',
                    hex8: "#F2F2F2FF",
                    hsl: { h: 52.38095238095238, s: 0, l: 0.9490196078431372, a: 1 },
                    hsv: { h: 52.38095238095238, s: 0, v: 0.9490196078431372, a: 1 },
                    oldHue: 52.38095238095238,
                    rgba: { r: 242, g: 242, b: 242, a: 1 },
                    source: "hex"
                }
            }else{
                this.colors = value
            }
            
        },
        async addComment(model,message,colors,loading = true){
            this.$validator.validate().then(async(valid) => {
                if (valid) {
                    if (loading === true) {
                         this.$emit('setLoadding',true)
                    }
                    let obj ={
                        canvasId : this.canvas._id,
                        modelId : (model !== null)?model._id:null,
                        note:{
                            title:message,
                            color:colors
                        }
                    }

                    // HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
                    await HTTP.post(`add/note/in/canvas`,obj)
                    .then(res => {
                        
                        if (res.data.success) {
                            if (loading === true) {
                                this.$buefy.toast.open({
                                    message:'Succeed!',
                                    type: 'is-success'
                                })
                            }
                            this.fetchCanvas(loading)
                            this.resetCanvasModal()
                            let socket ={
                                canvasId : this.canvas._id,
                            }
                
                            this.$socket.emit('getCanvas',socket);
                            this.$socket.emit('listCanvasHistory',socket);
                        }else{
                            if (res.data.message == 'invalid authorization') {
                                this.expireToken()
                            }else{
                                this.alertFailError()
                                this.$emit('setLoadding',false)
                            }
 
                        }
                    })
                    .catch(e => {
                        this.$emit('setLoadding',false)
                        this.alertCatchError()
                        console.log("add/note/in/canvas/error",e);
                    });
                }
            }) 
            return 'Ok'
        },
        editComment(){
            this.$validator.validate().then(valid => {
                if (valid) {
                    this.$emit('setLoadding',true)
                    let obj ={
                        canvasId : this.canvas._id,
                        noteId : this.noteId,
                        note:{
                            title:this.message,
                            color:this.colors
                        }
                    }
                        
                    // HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
                    HTTP.post(`edit/note/in/canvas`,obj)
                    .then(res => {
                        // console.log("edit/note/in/canvas", res);  
                        if (res.data.success) {
                            this.$buefy.toast.open({
                                message:'Succeed!',
                                type: 'is-success'
                            })
                            this.fetchCanvas()
                            this.resetCanvasModal()
                            let socket ={
                                canvasId : this.canvas._id,
                            }
                            this.$socket.emit('getCanvas',socket);
                            this.$socket.emit('listCanvasHistory',socket);
                        }else{
                            if (res.data.message == 'invalid authorization') {
                                this.expireToken()
                            }else{
                                this.alertFailError()
                                this.$emit('setLoadding',false)
                            }
                        }
                    })
                    .catch(e => {
                        this.$emit('setLoadding',false)
                        this.alertCatchError()
                        console.log("edit/note/in/canvas/error",e);
                    });
                }
            })
        },
        async delComment(id,loading=true){
            if (loading === true) {
                this.$emit('setLoadding',true)
            }
            let obj ={
                canvasId:this.canvas._id,
                noteId : id
            }
  
            // HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
            await HTTP.post(`delete/note/in/canvas`,obj)
            .then(res => {
                // console.log("delete/note/in/canvas", res);  
                if (res.data.success) {
                    if (loading === true) {
                        this.$buefy.toast.open({
                            message:'Succeed!',
                            type: 'is-success'
                        })
                    }
                    this.fetchCanvas(loading)
                    this.resetCanvasModal()
                    let socket ={
                        canvasId : this.canvas._id,
                    }
                    this.$socket.emit('getCanvas',socket);
                    this.$socket.emit('listCanvasHistory',socket);
                }else{ 
                    if (res.data.message == 'invalid authorization') {
                        this.expireToken()
                    }else{
                        this.alertFailError()
                        this.$emit('setLoadding',false)
                    }
                }
            })
            .catch(e => {
               this.$emit('setLoadding',false)
                this.alertCatchError(e)
                // console.log("delete/note/in/canvas/error",e);
            });
        },
        async updateIndexNote(notes){
            let obj ={
                canvasId : this.canvas._id,
                notes :notes
            }
         
            await HTTP.post(`update/index/note`,obj)
            .then(res => {
                if (res.data.success) {
                    this.$emit('statusSave',2)
                    this.fetchCanvas(false)
                    this.$socket.emit('getCanvas',{canvasId:this.canvas._id})
                }else{
                    if (res.data.message == 'invalid authorization') {
                        this.expireToken()
                    }else{
                        this.alertFailError()
                    }
                }
            })
            .catch(e => {
                setTimeout(() => {
                    this.$emit('statusSave',0)
                }, 5000);
                this.alertCatchError(e)
                // console.log("add/note/in/canvas/error",e);
            });
            return "Ok"
        },
        
        getColorCard(hex){
            let filter = this.colorList.filter(filter=> filter.hex === hex)
            if (filter.length > 0) {
                return hex
            }else{
                 return color.hex
            }
        }
    },
    
}
</script>
<style scoped>

.thumbnailXl{
   height: 480px;
}
.thumbnailL{
    height: 250px;
}
.thumbnailM{
    height: 200px;
}
.thumbnailS{
    height: 150px;
}
ul{
    word-break: break-word
}
.delIcon{
    color: #b5b5b5!important;
    font-size: 1em;
}
.delIcon:hover{
    color: #ff3860 !important;
}
.boxPicker{
    position: absolute;
    right: 20px;
    top: 120px;
    z-index: 10;
}
.selectColor:focus ~ .boxPicker{
    visibility: visible!important;
}
.boxPicker{
    visibility: hidden!important;
}
.boxPicker:hover{
    visibility: visible!important;
}
:focus {
    outline: -webkit-focus-ring-color auto 0px!important;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.listGroupBox{
    margin: 5px;
    border-radius: 4px;
}
.listGroupItem {
    border-radius: 4px;
    padding: 5px 20px 5px 5px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
.listGroupItem:hover {
    cursor: pointer;
}
.listGroupItem:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}
.dsBox{
    display: -webkit-box;
}
.selectColor{
    color: #fff;
    position: absolute;
}
.colorIcon{
    font-size: 1.3em;
}
.listDelBox{
    top:0;
    right: 5px;
    line-height: 16px;
    position: absolute;
    cursor: pointer;
    width: 12px;
    text-align: center;
    color: #7a7a7a;
}
.listDelBox:hover{
    color: #2B2B2B;
}
.editTrigger{
    width: 100%;
    height: 50px;
    position: absolute;
}
</style>
