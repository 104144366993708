<template>
  <div>
    <b-loading :active.sync="isLoadingPage"></b-loading>
    <div class="level mg-b-3">
      <p class="has-text-left  has-text-weight-normal is-size-4">
        Canvas list ({{ canvasList.length }} Canvas)
      </p>
      <div class="is-flex flex-ai-center pd-y-2">
        <div class="is-flex flex-ai-center pd-r-2">
          <p class="pd-r-2 is-size-6 has-text-weight-normal">Sort By:</p>
          <b-dropdown aria-role="list" position="is-bottom-left">
            <button class="tag button btn-info" slot="trigger">
              <span
                v-if="currentSort === 'canvasName' && currentSortDir === 'asc'"
                >Name A to Z</span
              >
              <span
                v-if="currentSort === 'canvasName' && currentSortDir === 'desc'"
                >Name Z to A</span
              >
              <span
                v-if="
                  formAuth !== null &&
                    currentSort === 'owned' &&
                    currentSortDir === 'asc'
                "
                >Created by A to Z</span
              >
              <span
                v-if="
                  formAuth !== null &&
                    currentSort === 'owned' &&
                    currentSortDir === 'desc'
                "
                >Created by Z to A</span
              >
              <span v-if="currentSort === '' || currentSort === null"
                >Please select</span
              >
              <b-icon icon="menu-down"></b-icon>
            </button>

            <b-dropdown-item
              @click="sortCanvas('canvasName', 'asc')"
              aria-role="listitem"
              >Name A to Z</b-dropdown-item
            >
            <b-dropdown-item
              @click="sortCanvas('canvasName', 'desc')"
              aria-role="listitem"
              >Name Z to A</b-dropdown-item
            >
            <b-dropdown-item
              v-if="formAuth !== null"
              @click="sortCanvas('owned', 'asc')"
              aria-role="listitem"
              >Created by A to Z</b-dropdown-item
            >
            <b-dropdown-item
              v-if="formAuth !== null"
              @click="sortCanvas('owned', 'desc')"
              aria-role="listitem"
              >Created by Z to A</b-dropdown-item
            >
          </b-dropdown>
        </div>

        <b-dropdown
          ref="filter"
          aria-role="list"
          position="is-bottom-left"
          class="db-filter"
        >
          <button class="tag button btn-info" type="button " slot="trigger">
            <template>
              <b-icon icon="filter" pack="fas" size="is-small"></b-icon>
              <span class="has-text-weight-normal">Filter</span>
            </template>
          </button>
          <div class="modal-search">
            <div class="pd-2">
              <p class="has-text-weight-normal is-size-5">
                <b-icon icon="filter" pack="fas" size="is-small"></b-icon>
                Filter
              </p>
            </div>
            <div class="pd-2">
              <b-field grouped>
                <b-input
                  class="is-medium"
                  type="search"
                  icon-pack="fas"
                  icon="search"
                  @input="filter"
                  @keyup.native.enter="[filter(), $refs.filter.toggle()]"
                  v-model="searchName"
                  placeholder="Canvas name"
                  expanded
                ></b-input>
              </b-field>
            </div>
            <div v-if="formAuth !== null" class="pd-2">
              <b-field grouped>
                <b-input
                  class="is-medium"
                  type="search"
                  icon-pack="fas"
                  icon="search"
                  v-model="searchCreatedBy"
                  @input="filter"
                  placeholder="Created by"
                  expanded
                ></b-input>
              </b-field>
            </div>
            <div class="pd-2">
              <div class="block">
                <div class="pd-y-1">
                  <p class="is-size-6 has-text-weight-normal">Pattern:</p>
                </div>
                
                <div v-for="item in patternList" :key="item._id">
                  <b-checkbox
                    v-model="searchPattern"
                    type="is-info"
                    @input="filter"
                    :native-value="item._id"
                  >
                    {{ item.partentName }}
                  </b-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="has-text-centered">
            <button
              @click="resetFilter"
              class="button mg-5 is-light btn-danger"
              type="button"
              slot="trigger"
            >
              <template>
                <b-icon icon="trash" pack="fas" size="is-small"></b-icon>
                <span class="has-text-weight-normal th">ล้างทั้งหมด</span>
              </template>
            </button>
          </div>
        </b-dropdown>
      </div>
    </div>
    <div
      v-if="canvasList.length > 0 || formAuth === null"
      class="columns mh-auto flex-wrap"
    >
      <div v-if="canvasList.length == 0" class="pd-5 column is-12">
        <p class="is-size-6 has-text-grey">Not found any canvas.</p>
      </div>
      <div
        v-if="formAuth !== 'invite' && formAuth !== 'inviteEmail'"
        class="column"
        :class="widthMax > 1280 ? 'is-3' : 'is-6'"
      >
        <div
          @click="openCanvasAdd"
          class="boxAddCanvas "
        >
          <p class="is-size-6 position-abs ">
            <b-icon pack="fas" icon="plus" size="is-small" title="Add"></b-icon>
            Add canvas
          </p>
        </div>
      </div>
      <div
        v-for="item in canvasList"
        :key="item.id"
        class="column"
        :class="widthMax > 1280 ? 'is-3' : 'is-6'"
      >
        <CanvasItem
          :item="item"
          @openModalCanvas="openModalCanvas"
          @confirmDeleteCanvas="confirmDeleteCanvas"
          @gotoDetail="gotoDetail"
          :getPattern="getPattern"
        />
      </div>
    </div>
    <div v-else class="pd-5">
      <p class="is-size-6 has-text-grey">Not found any canvas.</p>
    </div>

    <b-modal
      :active.sync="isAddCanvas"
      :width="640"
      @close="resetCanvasModal()"
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-left has-text-weight-normal">
            {{ modalType === "edit" ? "Edit canvas" : "Add canvas" }}
          </p>
        </header>

        <section class="modal-card-body has-text-left">
          <b-field
            label="Canvas Name"
            :type="{ 'is-danger': errors.has('name') }"
            :message="errors.has('name') ? 'กรุณากรอกชื่อ Canvas' : ''"
          >
            <b-input
              class="is-medium"
              type="text"
              v-model="name"
              placeholder="Canvas name"
              v-validate="'required'"
              name="name"
              expanded
            ></b-input>
          </b-field>

          <div class="block">
            <p class="pd-y-2 is-size-6 has-text-dark has-text-weight-normal">
              Pattern
            </p>
            <span v-for="item in patternList" :key="item._id">
              <b-radio
                v-model="pattern"
                type="is-info"
                v-validate="'required'"
                name="pattern"
                :disabled="modalType === 'edit'"
                :native-value="item._id"
                v-if="item.partentName === 'Navigator' || modalType === 'edit'"
              >
                {{ item.partentName }}
              </b-radio>
            </span>

            <div v-if="errors.has('pattern')">
              <p class="is-size-7 has-text-danger">
                กรุณาเลือกประเภทของ Canvas
              </p>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot flex-js-end">
          <button class="button btn-min-w " @click="resetCanvasModal">
            Close
          </button>
          <button
            class="button btn-min-w is-success"
            @click="modalType === 'edit' ? editCanvas() : addCanvas()"
          >
            Save
          </button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>
<script>
import CanvasItem from "./CanvasItem";
import { HTTP } from "@/service/axios";

export default {
  name: "CanvasList",
  components: {
    CanvasItem,
  },
  data() {
    return {
      canvasListConst: [],
      canvasList: [],
      searchPattern: [],
      searchName: null,
      isAddCanvas: false,
      pattern: null,
      name: null,
      canvasId: null,
      patternList: [],
      modalType: null,
      isLoadingPage: false,
      searchCreatedBy: null,
      widthMax: window.innerWidth,
      currentSortDir: "asc",
      currentSort: "",
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    batch() {
      return this.$store.state.batch;
    },
    formAuth() {
      return this.$store.state.formAuth;
    },
  },
  mounted() {
    this.fetchPatternAll();
    this.fetchCanvasAll();
  },
  methods: {
    openCanvasAdd(){
      let filter = this.patternList.filter((filter)=>filter.partentName === 'Navigator')
      this.pattern  = filter.length > 0?filter[0]._id:''
      this.modalType = 'add'
      this.isAddCanvas = true
    },
    async fetchCanvasAll() {
      this.isLoadingPage = true;
      let obj = {
        data: {
          batchId: this.batch.id,
        },
        token: this.token,
      };

      let path = ''
      if (this.formAuth === "invite" || this.formAuth === "inviteEmail") {
        path = `api/user/invite/get/all/canvas`
      } else {
        path = `get/all/canvas`
      }

      HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
      await HTTP.post(path,obj.data)
      .then(res => { 
          if (res.data.success) {
              let arr = [];

              let sort = res.data.obj.sort((a, b) => {
                  let modifier = -1;
                  if (a['createDate'] < b['createDate']) return -1 * modifier;
                  if (a['createDate'] > b['createDate']) return 1 * modifier;
                  return 0;
              })

              sort.map(async (item) => {
                let owned = await this.getCanvasByName(item.userId);
                item["owned"] = owned;
                arr.push(item);
              });

              this.canvasList = arr
              this.canvasListConst = arr
          }else{
            if (res.data.message == 'invalid authorization') {
              this.$store.commit('resetState')
              this.$router.push('/sign-in')
            }else{
              this.canvasList = []
              this.canvasListConst = []
            }
          }
      })
      .catch(e => {
          console.log("fetchCanvasAll error",e.response);
          if (e.response.data.message == 'invalid authorization') {
            this.$store.commit('resetState')
            this.$router.push('/sign-in')
          }
      }).finally(()=>{
        setTimeout(() => {
          this.isLoadingPage = false;
        }, 500);
      })
    },
    sortCanvas(name, dir) {
      this.currentSort = name;
      this.currentSortDir = dir;

      let canvas = this.canvasList.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (this.currentSort === "owned") {
          if (a[this.currentSort].name < b[this.currentSort].name)
            return -1 * modifier;
          if (a[this.currentSort].name > b[this.currentSort].name)
            return 1 * modifier;
        } else {
          if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        }

        return 0;
      });

      this.canvasList = canvas;
    },
    async getCanvasByName(id) {
      let obj = {
        userId: id,
      };

      let resData = {};

      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      await HTTP.post(`get/user/by/id`, obj)
        .then((res) => {
          if (res.data.success) {
            resData = res.data.obj;
          } else {
            if (res.data.message == "invalid authorization") {
              this.expireToken();
            } else {
              resData = {};
            }
          }
        })
        .catch((e) => {
          console.log("get/user/by/id/error", e);
        });

      return resData;
    },
    fetchPatternAll() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post(`get/all/pattern`)
        .then((res) => {
         
          if (res.data.success) {
            this.patternList = res.data.obj;
          } else {
            if (res.data.message == "invalid authorization") {
              this.expireToken();
            } else {
              this.patternList = [];
            }
          }
        })
        .catch((e) => {
          console.log("get/all/pattern/error", e);
        });
    },
    addCanvas() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.isLoadingPage = true;
          let obj = {
            batch: {
              id: this.batch.id,
              name: this.batch.name,
            },
            patternId: this.pattern,
            canvasName: this.name,
          };

          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          HTTP.post(`create/canvas`, obj)
            .then((res) => {
 
              if (res.data.success) {
                this.$buefy.toast.open({
                  message: "Succeed!",
                  type: "is-success",
                });
                this.fetchCanvasAll();
                this.resetCanvasModal();
              } else {
                if (res.data.message == "invalid authorization") {
                  this.expireToken();
                } else {
                  this.isLoadingPage = false;
                  this.alertFailError();
                }
              }
            })
            .catch((e) => {
              this.isLoadingPage = false;
              this.alertCatchError();
              console.log("create/canvas/error", e);
            });
        }
      });
    },
    delCanvas(id) {
      this.isLoadingPage = true;
      let obj = {
        canvasId: id,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post(`delete/canvas`, obj)
        .then((res) => {
          // console.log("delete/canvas", res);
          if (res.data.success) {
            this.$buefy.toast.open({
              message: "Succeed!",
              type: "is-success",
            });
            this.fetchCanvasAll();
            this.resetCanvasModal();
          } else {
            if (res.data.message == "invalid authorization") {
              this.expireToken();
            } else {
              this.isLoadingPage = false;
              this.alertFailError();
            }
          }
        })
        .catch((e) => {
          this.isLoadingPage = false;
          this.alertCatchError();
          console.log("delete/canvas/error", e);
        });
    },
    editCanvas() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.isLoadingPage = true;
          let obj = {
            canvasId: this.canvasId,
            canvasName: this.name,
          };
          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          HTTP.post(`edit/canvas`, obj)
            .then((res) => {
              // console.log("edit/canvas", res);
              if (res.data.success) {
                this.$buefy.toast.open({
                  message: "Succeed!",
                  type: "is-success",
                });
                this.fetchCanvasAll();
                this.resetCanvasModal();
              } else {
                if (res.data.message == "invalid authorization") {
                  this.expireToken();
                } else {
                  this.isLoadingPage = false;
                  this.alertFailError();
                }
              }
            })
            .catch((e) => {
              this.isLoadingPage = false;
              this.alertCatchError();
              console.log("edit/canvas/error", e);
            });
        }
      });
    },
    getPattern(id) {
      let patternList = this.patternList.filter((item) => item._id === id);
      if (patternList.length > 0) {
        return patternList[0];
      } else {
        return null;
      }
    },
    resetFilter() {
      this.searchName = null;
      this.searchPattern = [];
      this.searchCreatedBy = null;
      this.canvasList = this.canvasListConst;
      if (typeof this.$refs.filter !== "undefined") {
        this.$refs.filter.isActive = false;
      }
    },
    filter() {
      if (
        typeof this.canvasListConst.length !== "undefined" &&
        this.canvasListConst.length > 0
      ) {
        let filter = this.canvasListConst.filter(
          (search) =>
            ((this.searchName !== null &&
              search.canvasName
                .toLowerCase()
                .indexOf(this.searchName.toLowerCase()) > -1) ||
              this.searchName === null) &&
            ((this.searchCreatedBy !== null &&
              (search.owned.name
                .toLowerCase()
                .indexOf(this.searchCreatedBy.toLowerCase()) > -1 ||
                search.owned.surname
                  .toLowerCase()
                  .indexOf(this.searchCreatedBy.toLowerCase()) > -1)) ||
              this.searchCreatedBy === null)
        );
        let mapData = [];
        if (this.searchPattern.length > 0) {
          this.searchPattern.map((data) => {
            let dataArray = filter.filter((sea) => {
              return sea.patternId == data;
            });
            if (dataArray.length > 0) {
              mapData = mapData.concat(dataArray);
            }
          });
        } else {
          mapData = filter;
        }
        //console.log('filter',mapData,this.searchPattern)
        this.canvasList = mapData;
      } else {
        this.canvasList = [];
      }
      // if (typeof this.$refs.filter !== 'undefined') {
      //     this.$refs.filter.isActive = false;
      // }
    },

    async gotoDetail(item) {
      let pattern = "";
      let name = this.getPattern(item.patternId).partentName;

      switch (name) {
        case "LEAN":
          pattern = "lean";
          break;
        case "BMC":
          pattern = "bmc";
          break;
        case "Navigator":
          pattern = "navigator";
          break;
        default:
          break;
      }
      let obj = {
        canvasId: item._id,
      };
      // HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post(`get/canvas/byId`, obj)
        .then((res) => {
          //console.log("get/canvas/byId", res);
          if (res.data.success) {
            this.$store.commit("setPage", "canvasDetail");
            this.$store.commit("setCanvasOwned", item.owned);
            this.$router.push(`/canvas?id=${item._id}`)
            // console.log("this.formAuth",this.formAuth,item)
            if (
              (this.formAuth === "invite" || this.formAuth === "inviteEmail") &&
              typeof item.role !== "undefined"
            ) {
              this.$store.commit("setRole", item.role);
            } else {
              this.$store.commit("setRole", 1);
            }
          } else {
            if (res.data.message == "invalid authorization") {
              this.expireToken();
            }
          }
        })
        .catch((e) => {
          this.isLoadingPage = false;
          this.alertCatchError();
          console.log("get/canvas/byId/error", e);
        });
    },
    openModalCanvas(item) {
      this.canvasId = item._id;
      this.name = item.canvasName;
      this.pattern = item.patternId;
      this.modalType = "edit";
      this.isAddCanvas = true;
    },
    resetCanvasModal() {
      this.isAddCanvas = false;
      setTimeout(() => {
        this.pattern = null;
        this.name = null;
        this.canvasId = null;
      }, 500);
    },
    confirmDeleteCanvas(name, id) {
      this.$buefy.dialog.confirm({
        title: "Deleting canvas",
        message: `Are you sure you want to <b>delete</b> ${name}? This action cannot be undone.`,
        confirmText: "Delete canvas",
        type: "is-danger",
        hasIcon: true,
        iconPack: "fas",
        onConfirm: () => this.delCanvas(id),
      });
    },
  },
};
</script>
<style scoped></style>
