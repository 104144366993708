<template>
    <div class="canvas">
        <div class="card">
            <div  @click="$emit('gotoDetail',item)" class="boxImage">
                <div  class="viewIcon index-layer-2">
                    <p class="is-flex flex-ai-center has-text-grey iconHover cr-pointer"> 
                        <b-icon
                            icon="eye"
                            custom-size="fa-lg"
                            size="is-medium">
                        </b-icon>
                        View
                    </p>
                </div>
                <div class="card-image cr-pointer w-100">
                    <figure class="image is-4by3">
                        <img v-if="typeof item.thumbnail !== 'undefuned' && item.thumbnail !== '' && item.thumbnail !== null" :src="item.thumbnail" class="hoverImag">
                        <img v-else-if="getPattern(item.patternId) !== null && getPattern(item.patternId) === 'BMC'" :src="require('@/assets/img/bmc.jpg')" class="hoverImag">
                        <img v-else-if="getPattern(item.patternId) !== null && getPattern(item.patternId) === 'LEAN'" :src="require('@/assets/img/lean.jpg')" class="hoverImag">
                        <img v-else :src="require('@/assets/img/navigator.jpg')" class="hoverImag">
                    </figure>
                </div>
            </div>
            <div class="card-content border-t">
                <div class="level is-flex-mobile">
                    <div class="level-left">
                        <div class="level-item has-text-left">
                            <div>
                            <p class="is-size-6 has-text-weight-normal textName"  :title="item.canvasName">{{item.canvasName}}</p>
                            <p v-if="formAuth === 'invite' ||  formAuth === 'inviteEmail'" class="is-size-7 subtitle has-text-grey textCreated text-over " >Created by: {{item.owned.name}} {{item.owned.surname}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="level-right is-flex-mobile menu" v-if="formAuth !== 'invite' && formAuth !== 'inviteEmail'">
                        <a class="level-item text-grey-lighter iconHover" @click="$emit('openModalCanvas',item)">
                            <b-icon
                                pack="fas"
                                icon="edit"
                                size="is-small"
                            ></b-icon>
                        </a>   
                        <a class="level-item text-grey-lighter iconDelHover" @click="$emit('confirmDeleteCanvas',item.canvasName,item._id)">
                            <b-icon
                                pack="fas"
                                icon="trash"
                                size="is-small"
                            ></b-icon>
                        </a>  
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'CanvasItem',
    props:['item','openModalCanvas','confirmDeleteCanvas','gotoDetail','getPattern'],
    computed: {
        formAuth(){
            return this.$store.state.formAuth
        },
        pattern(){
            return this.$store.state.pattern
        }
    },
    
}
</script>
<style scoped>
.boxCanvas{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}
.boxCanvas:hover{
    color:#167df0;
}
.iconHover:hover{
    color:#0080E0!important;
}
.iconDelHover:hover{
    color:#FF6262!important;
}
.image.is-4by3{
    padding-top:220px!important;
}
.viewIcon{
    position: absolute;
}
.canvas .card, .canvas .card img{
    border-radius:4px;
}
.canvas:hover .card  {
    -webkit-box-shadow:0 2px 3px  rgba(15,76,130, 0.25), 0 0 0 1px  rgba(15,76,130, 0.25)!important;
    box-shadow: 0 2px 3px  rgba(15,76,130, 0.25), 0 0 0 1px  rgba(15,76,130, 0.25)!important;
}
.canvas .card-content .menu  {
    opacity: 0;
    transition: all .25s ease;
}
.canvas:hover .card-content .menu  {
    opacity: 1;
}
.boxImage{
    display: flex;
    align-items: center;
    justify-content: center;
}
.boxImage .viewIcon  {
    display: none
}
.boxImage:hover .viewIcon  {
    display: unset
}
.boxImage:hover .hoverImag{
    opacity: 0.5;
}

.canvas .textName{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 17.5vw;
}
.canvas:hover .textName  {
    color: #0F4C82!important;
}
.canvas:hover .textCreated  {
    color: #4a4a4a!important;
}

@media only screen and (max-width: 768px) {
    .canvas .textName{
        width: 63vw;
    }
    .canvas .card-content .menu, .canvas .card-content .menu .level-item {
        margin:0!important;
    }
     .canvas .card-content .menu .level-item:not(:last-child){
         margin-right:0.35rem!important;
    }
}

.card-content {
    padding: 0.75rem 1.2rem!important;
}


</style>
