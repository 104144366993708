<template>
  <div>
    <DefaultTemplate>
        <CanvasDetail v-if="page === 'canvasDetail'"/>
        <CanvasList v-else/>
    </DefaultTemplate>
  </div>
</template>
<script>

import DefaultTemplate from '@/template/DefaultTemplate'
import CanvasDetail from '@/components/canvas/CanvasDetail'
import CanvasList from '@/components/canvas/CanvasList'

export default {
    components:{
        DefaultTemplate,
        CanvasDetail,
        CanvasList
    },
    computed: {
      page(){
        return this.$store.state.page
      }
    },
    mounted() {
      if (typeof this.$route.query.id == 'undefined') {
        this.$store.commit('setPage','canvasList')
      }
    },
};
</script>
<style scoped>
	
</style>