

import Vue from 'vue'


Vue.mixin({
    methods: {
        getTitle(pattern,num){
            let title = ''
            if (pattern === 'lean') {
                switch (num) {
                    case 0:
                        title = 'PROBLEM'
                        break;
                    case 1:
                        title = 'EXISTING ALTERNATIVES'
                        break;
                    case 2:
                        title = 'SOLUTION'
                        break;
                    case 3:
                        title = 'KEY METRICS'
                        break;
                    case 4:
                        title = 'UNIQUE VALUE PROPOSITION'
                        break;
                    case 5:
                        title = 'HIGH-LEVEL CONCEPT'
                        break;
                    case 6:
                        title = 'UNFAIR ADVANTAGE'
                        break;
                    case 7:
                        title = 'CHANNELS'
                        break;
                    case 8:
                        title = 'CUSTOMER SEGMENTS'
                        break;
                    case 9:
                        title = 'EARLY ADOPTERS'
                        break;
                    case 10:
                        title = 'COST STRUCTURE'
                        break;
                    case 11:
                        title = 'REVENUE STREAMS'
                        break;
                    default:
                        break;
                }
            }else if(pattern === 'bmc'){
                switch (num) {
                    case 0:
                        title = 'KEY PARTNERS'
                        break;
                    case 1:
                        title = 'KEY ACTIVITIES'
                        break;
                    case 2:
                        title = 'KEY RESOURCES'
                        break;
                    case 3:
                        title = 'VALUE PROPOSITIONS'
                        break;
                    case 4:
                        title = 'CUSTOMER RELATIONSHIPS'
                        break;
                    case 5:
                        title = 'CHANNELS'
                        break;
                    case 6:
                        title = 'CUSTOMER SEGMENTS'
                        break;
                    case 7:
                        title = 'COST STRUCTURE'
                        break;
                    case 8:
                        title = 'REVENUE STREAMS'
                        break;
                    default:
                        break;
                }
            }else{
                switch (num) {
                    case 0:
                        title = 'ตราสินค้า (Brand)'
                        break;
                    case 1:
                        title = 'ทีมงาน (Team)'
                        break;
                    case 2:
                        title = 'คู่ค้าทางธุรกิจ (Partner)'
                        break;
                    case 3:
                        title = 'กระบวนการและระบบ (Process & System)'
                        break;
                    case 4:
                        title = 'ปัญหา (Pain Point)'
                        break;
                    case 5:
                        title = 'คู่แข่งและตลาด (Competitor & Market)'
                        break;
                    case 6:
                        title = 'แนวคิดคุณค่า (Value Proposition)'
                        break;
                    case 7:
                        title = 'กลุ่มเป้าหมาย (Target)'
                        break;
                    case 8:
                        title = 'ขนาดตลาด (Market Size)'
                        break;
                    case 9:
                        title = 'กลยุทธ์ (Strategy)'
                        break;
                    case 10:
                        title = 'ช่องทางจำหน่าย (Channel)'
                        break;
                    case 11:
                        title = 'การสื่อสารทางการตลาด (Marketing Communication)'
                        break;
                    case 12:
                        title = 'ต้นทุน (Cost)'
                        break;
                    case 13:
                        title = 'รายได้ (Revenue)'
                        break;
                    case 14:
                        title = 'จุดมุ่งหมาย (Purpose)'
                        break;
                    case 15:
                        title = 'วัตถุประสงค์ (Objectives)'
                        break;
                    case 16:
                        title = 'ผลลัพธ์หลัก (Key Results)'
                        break;
                    default:
                        break;
                }
            }
           
            return title
        },
        getDetail(pattern,num){
            let title = ''
            if (pattern === 'lean') {
                switch (num) {
                    case 0:
                        title = 'List you top 1-3 problems.'
                        break;
                    case 1:
                        title = 'List how these problems are solved today.'
                        break;
                    case 2:
                        title = 'Outline a possible solution for each problem.'
                        break;
                    case 3:
                        title = 'List the key numbers that tell you how your business is doing'
                        break;
                    case 4:
                        title = 'Sigle, clear, compelling message that states why you are different and worth paying attention.'
                        break;
                    case 5:
                        title = 'List your X for Y analogy e.g. YouTube = Flickr for videos.'
                        break;
                    case 6:
                        title = 'Something that cannot easily be bought or copied.'
                        break;
                    case 7:
                        title = 'List your path to customers (inbound or outbound).'
                        break;
                    case 8:
                        title = 'List your target customers and users.'
                        break;
                    case 9:
                        title = 'List the characteristics of your ideal customers'
                        break;
                    case 10:
                        title = 'List your fixed and variable costs.'
                        break;
                    case 11:
                        title = 'List your sources of revenue.'
                        break;
                    default:
                        break;
                }
            }else if(pattern === 'bmc'){

            }else{
                switch (num) {
                    case 3:
                        title = ''
                        break;
                    case 5:
                        title = ''
                        break;
                    default:
                        break;
                }
                // switch (num) {
                //     case 0:
                //         title = 'link'
                //         break;
                //     case 1:
                //         title = 'check-circle'
                //         break;
                //     case 2:
                //         title = 'industry'
                //         break;
                //     case 3:
                //         title = 'gift'
                //         break;
                //     case 4:
                //         title = 'heart'
                //         break;
                //     case 5:
                //         title = 'truck'
                //         break;
                //     case 6:
                //         title = 'users'
                //         break;
                //     case 7:
                //         title = 'tags'
                //         break;
                //     case 8:
                //         title = 'hand-holding-usd'
                //         break;
                //     default:
                //         break;
                // }
            }
            return title
        },
        getIcon(pattern,num){
            let title = ''
            if (pattern === 'lean') {
                switch (num) {
                    case 0:
                        title = 'lock'
                        break;
                    case 1:
                        title = 'directions-fork'
                        break;
                    case 2:
                        title = 'key-variant'
                        break;
                    case 3:
                        title = 'chart-areaspline'
                        break;
                    case 4:
                        title = 'gift'
                        break;
                    case 5:
                        title = 'lightbulb-on'
                        break;
                    case 6:
                        title = 'rocket'
                        break;
                    case 7:
                        title = 'truck-fast'
                        break;
                    case 8:
                        title = 'account-multiple'
                        break;
                    case 9:
                        title = 'face'
                        break;
                    case 10:
                        title = 'tag-multiple'
                        break;
                    case 11:
                        title = 'cash'
                        break;
                    default:
                        break;
                }
            }else if(pattern == 'bmc'){
                switch (num) {
                    case 0:
                        title = 'link'
                        break;
                    case 1:
                        title = 'check-circle'
                        break;
                    case 2:
                        title = 'industry'
                        break;
                    case 3:
                        title = 'gift'
                        break;
                    case 4:
                        title = 'heart'
                        break;
                    case 5:
                        title = 'truck'
                        break;
                    case 6:
                        title = 'users'
                        break;
                    case 7:
                        title = 'tags'
                        break;
                    case 8:
                        title = 'hand-holding-usd'
                        break;
                    default:
                        break;
                }
            }else{
                switch (num) {
                    case 0:
                        title = 'star'
                        break;
                    case 1:
                        title = 'users'
                        break;
                    case 2:
                        title = 'user-friends'
                        break;
                    case 3:
                        title = 'globe'
                        break;
                    case 4:
                        title = 'heart'
                        break;
                    case 5:
                        title = 'people-carry'
                        break;
                    case 6:
                        title = 'gem'
                        break;
                    case 7:
                        title = 'walking'
                        break;
                    case 8:
                        title = 'expand-arrows-alt'
                        break;
                    case 9:
                        title = 'chess'
                        break;
                    case 10:
                        title = 'flag'
                        break;
                    case 11:
                        title = 'bullhorn'
                        break;
                    case 12:
                        title = 'donate'
                        break;
                    case 13:
                        title = 'chart-line'
                        break;
                    case 14:
                        title = 'bullseye'
                        break;
                    case 15:
                        title = 'clipboard-list'
                        break;
                    case 16:
                        title = 'clipboard-check'
                        break;
                    default:
                        break;
                }
            }

            return title
        },
        getIndex(pattern,num){
            let index = null
           
            if (pattern === 'lean') {
                switch (num) {
                    case 0:
                        index = 0
                        break;
                    case 1:
                        index = 1
                        break;
                    case 2:
                        index = 2
                        break;
                    case 3:
                        index = 3
                        break;
                    case 4:
                        index = 4
                        break;
                    case 5:
                        index = 5
                        break;
                    case 6:
                        index = 6
                        break;
                    case 7:
                        index = 7
                        break;
                    case 8:
                        index = 8
                        break;
                    case 9:
                        index = 9
                        break;
                    case 10:
                        index = 10
                        break;
                    case 11:
                        index = 11
                        break;
                    default:
                        break;
                }
            }else{
                switch (num) {
                    case 0:
                        index = 0
                        break;
                    case 1:
                        index = null
                        break;
                    case 2:
                        index = 1
                        break;
                    case 3:
                        index = 2
                        break;
                    case 4:
                        index = 3
                        break;
                    case 5:
                        index = null
                        break;
                    case 6:
                        index = 4
                        break;
                    case 7:
                        index = 5
                        break;
                    case 8:
                        index = 6
                        break;
                    case 9:
                        index = null
                        break;
                    case 10:
                        index = 7
                        break;
                    case 11:
                        index = 8
                        break;
                    default:
                        break;
                }
            }

            return index
        },
    },
  })
  