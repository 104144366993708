import Vue from 'vue';
import Router from 'vue-router';
import ErrorScreen from './views/ErrorScreen'
import Canvas from './views/Canvas'
import AuthCheck from './views/AuthCheck'
import AuthInvite from './views/AuthInvite'
import store from './store';

Vue.use(Router);

const ifAuthenticated = (to, from, next) => {
  if (typeof store.state.token === 'undefined' || store.state.token === null || store.state.token === '') {
    next('/sign-in')
  }else{
    next()
  }
};

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '*', component: ErrorScreen }, 
    {
      path: '/',
      redirect: '/canvas',
    },
    {
      path: '/canvas',
      name: 'canvas',
      component: Canvas,
      meta: {
        title: ''
      },
      props: (route) => ({
        routeParams: route.query,
      }),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/sign-in',
      name: 'sign in',
      component: () => import('@/views/Signin'),
      meta: {
        title: ''
      },
    },
    {
      path: '/sign-up',
      name: 'sign up',
      props: (route) => ({ 
        object:route.query
      }),
      component: () => import('@/views/SignUp'),
      meta: {
        title: ''
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot password',
      component: () => import('@/views/ForgotPassword'),
      meta: {
        title: ''
      },
    },
    {
      path: '/reset-password',
      name: 'reset password',
      props: (route) => ({ 
        token:route.query
      }),
      component: () => import('@/views/ResetPassword'),
      meta: {
        title: ''
      },
    },
    {
      path: '/auth/check',
      name: 'authCheck',
      props: (route) => ({ 
        token:route.query
      }),
      component: AuthCheck,
      meta: {
        title: ''
      },
    },
    {
      path: '/auth/invite/:token',
      name: 'authCheck',
      component: AuthInvite,
      meta: {
        title: ''
      },
    },
  ],
});