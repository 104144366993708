import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Buefy from "buefy";
// import 'buefy/dist/buefy.css';
import store from "./store";
import VeeValidate from "vee-validate";
import VueJWT from "vuejs-jwt";
import VueSocketio from "vue-socket.io";
import io from "socket.io-client";
import VueHtml2Canvas from "vue-html2canvas";
import "./service/alertMsg";
import "./service/checkToken";
import "./service/canvas";
import VueGtag from "vue-gtag";
import GoogleLogin from "vue-google-login";
import GAuth from "vue-google-oauth2";
import JWT from 'jsonwebtoken'

const gauthOption = {
  clientId:
    "131212105511-9qpbqji63m4ebuejq63ufafch8cpjldn.apps.googleusercontent.com",
  scope: "profile email",
  prompt: "select_account",
};

Vue.config.productionTip = false;

Vue.use(JWT)
Vue.use(GAuth, gauthOption);
Vue.use(VueHtml2Canvas);
Vue.use(VeeValidate);
Vue.use(Buefy);
Vue.use(VueJWT);
Vue.use(
  new VueSocketio({
    connection: io(process.env.VUE_APP_BASE_URL),
  })
);

const isProd = process.env.NODE_ENV === "production";
Vue.config.performance = !isProd;

Vue.use(
  VueGtag,
  {
    config: { id: "UA-171574472-1" },
    enabled: !isProd,
  },
  router
);

Vue.use(GoogleLogin, {
  client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
});

// Vue.filter('snippetSS', (value) => {
//   if (value != undefined) {
//     let cut = 7
//     let numSliceFrist = value.slice(0, cut)
//     let regReplaceFrist = numSliceFrist.replace(/[์ ื ิ ๋ ่ ็ ้ ๊ ี ํ ั ึ ู ุ]/g,"");
//     let numPoolFrist = numSliceFrist.length - regReplaceFrist.length

//     let numSliceLast = value.slice(cut,cut+numPoolFrist)
//     let regReplaceLast = numSliceLast.replace(/[์ ื ิ ๋ ่ ็ ้ ๊ ี ํ ั ึ ู ุ]/g,"");
//     let numPoolLast = numSliceLast.length - regReplaceLast.length

//     const char = value.length;
//     let charTotal = cut + numPoolFrist + numPoolLast

//     if (char <= charTotal) {
//       return value;
//     }
//     return `${value.slice(0, charTotal)}...`;
//   }
// });

// Vue.filter('snippetS', (value) => {
//   if (value != undefined) {
//     let cut = 14
//     let numSliceFrist = value.slice(0, cut)
//     let regReplaceFrist = numSliceFrist.replace(/[์ ื ิ ๋ ่ ็ ้ ๊ ี ํ ั ึ ู ุ]/g,"");
//     let numPoolFrist = numSliceFrist.length - regReplaceFrist.length

//     let numSliceLast = value.slice(cut,cut+numPoolFrist)
//     let regReplaceLast = numSliceLast.replace(/[์ ื ิ ๋ ่ ็ ้ ๊ ี ํ ั ึ ู ุ]/g,"");
//     let numPoolLast = numSliceLast.length - regReplaceLast.length

//     const char = value.length;
//     let charTotal = cut + numPoolFrist + numPoolLast

//     if (char <= charTotal) {
//       return value;
//     }
//     return `${value.slice(0, charTotal)}...`;
//   }
// });

Vue.filter("upload", (value) => {
  if (value != undefined) {
    let responsive = window.innerWidth;
    let cut = responsive < 768 ? 12 : 60;
    let numSliceFrist = value.slice(0, cut);
    let replacStr = /[ุิ-ู่-๋]/g;
    let regReplaceFrist = numSliceFrist.replace(replacStr, "");
    let numPoolFrist = numSliceFrist.length - regReplaceFrist.length;

    let numSliceLast = value.slice(cut, cut + numPoolFrist);
    let regReplaceLast = numSliceLast.replace(replacStr, "");
    let numPoolLast = numSliceLast.length - regReplaceLast.length;

    const char = value.length;
    let charTotal = cut + numPoolFrist + numPoolLast;

    if (char <= charTotal) {
      return value;
    }
    return `${value.slice(0, charTotal)}...`;
  }
});

Vue.filter("dateTime", (value) => {
  if (value !== "" && value !== null) {
    let dateFormat =
      addZeroBefore(new Date(value).getDate()) +
      "/" +
      addZeroBefore(new Date(value).getMonth() + 1) +
      "/" +
      (new Date(value).getFullYear() + 543) +
      " " +
      addZeroBefore(new Date(value).getHours()) +
      ":" +
      addZeroBefore(new Date(value).getMinutes()) +
      " น.";
    return dateFormat;
  } else {
    return null;
  }
});

function addZeroBefore(n) {
  return (n < 10 ? "0" : "") + n;
}

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
