<template>
  <div>

  </div>
</template>
<script>
import {HTTP} from '@/service/axios'
export default {
    props: {
      token: {
        type: Object,
        default: null
      }
    },
    mounted() {
        var jwt = require('jsonwebtoken');
        this.$store.commit('resetState')

        let obj = jwt.verify(this.token.token, this.token.uid)
        if (obj !== null) {
            this.addUserIntegreat(obj)
        }
    },
    methods: {
      addUserIntegreat(input){
            
            let obj={
              email : input.email,
              titleName:input.titleName,
              name: input.name,
              surname : input.surname,
              telephoneNumber: input.telephoneNumber,
              position:input.position,
              userId:input.userId,
            }

            HTTP.post(`check/email/outside`,obj)
            .then(res => {
                if (res.data.success) {
                  let user={
                      token:res.data.tokenAuth
                  }

                  this.$store.commit('setToken',res.data.tokenAuth)
                  
                  let batch ={
                    id:input.batchId,
                    name:input.batchName
                  }

                  this.$store.commit('setFormAuth',input.formAuth)
                  this.$store.commit('setBatch',batch)
                  this.$store.commit('setRole',1)
                  this.$store.dispatch("fetchUserDetail", user)
                  this.$router.push('/');
                }else{
                    if (res.data.message == 'invalid authorization') {
                        this.expireToken()
                    }
                }
            })
            .catch(e => {
                console.log("check/email/outside/error",e);
            });
      },
    },
};
</script>
<style scoped>
	
</style>