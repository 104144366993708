<template>
  <div>

  </div>
</template>
<script>
import {HTTP} from '@/service/axios'
export default {
    mounted() {
      this.$store.commit('resetState')
      this.coverTokenAuth()
    },
    computed: {
        token(){
            return this.$store.state.token
        }
    },
    methods: {
        coverTokenAuth(){
            let secret = "5x8s9t4j3/af-g8/g220v.fn1k_$5"
            let obj = this.$jwt.decode(this.$route.params.token, secret)
            //console.log('obj',obj)
            // if (obj !== null) {
            //     this.$store.commit('setToken',this.$route.params.token)
            //     this.$store.commit('setBatch',{})
            //     this.$store.commit('setRole',obj.role)
            //     this.$store.commit('setFormAuth','inviteEmail')

            //     this.fetchCanvasById(obj.canvasId)
            //     let user={
            //         token:this.token
            //     }

            //     this.$store.dispatch("fetchUserDetail", user)
            // }
            this.$router.push('/sign-up?email='+obj.email+'&uid='+obj.userId);
        },
        fetchCanvasById(id){
            let obj={
              canvasId:id
            }
            HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
            HTTP.post(`get/canvas/byId`,obj)
            .then(res => {
                // console.log("get/canvas/byId", res);  
                if (res.data.success) {
                    this.gotoDetail(res.data.obj)
                    this.fetchCanvasByName(res.data.obj.userId)
                }else{
                    if (res.data.message == 'invalid authorization') {
                        this.expireToken()
                    }
                }
            })
            .catch(e => {
                console.log("get/canvas/byId/error",e);
            });
        },
        fetchCanvasByName(id){
            let obj={
              userId:id
            }

            HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
            HTTP.post(`get/user/by/id`,obj)
            .then(res => {
                // console.log("get/user/by/id", res);
                if (res.data.success) {  
                    this.$store.commit('setCanvasOwned',res.data.obj)
                }else{
                    if (res.data.message == 'invalid authorization') {
                        this.expireToken()
                    }else{
                        this.$store.commit('setCanvasOwned',{})
                    } 
                }
            })
            .catch(e => {
                console.log("get/user/by/id/error",e);
            });
        },
        gotoDetail(item){  
            let   pattern = ''
            switch (item.patternId) {
                case '5d08cb9a83603807c55c236c':
                    pattern = 'lean'
                    break;
                case '5d08c95e83603807c55c2362':
                    pattern = 'bmc'
                    break;
                default:
                    break;
            }
            let canvas = JSON.parse(JSON.stringify(item))
            canvas['pattern'] = pattern
            this.$store.commit('setCanvas',canvas)
            this.$store.commit('setPage','canvasList') 
        }, 
    },
};
</script>
<style scoped>
	
</style>