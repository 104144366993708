import initState from './initState';

export default {
  setPage(state, payload) {
    state.page = payload;
  },
  setCanvasCodeTicket(state, payload){
    state.canvas['codePublic'] = payload;
  },
  setRole(state, payload){
    state.role = payload;
  },
  setBatch(state, payload){
    state.batch = payload;
  },
  setToken(state, payload){
    state.token = payload
  },
  setUser(state, payload){
    state.user = payload
  },
  setFormAuth(state, payload){
    state.formAuth = payload
  },
  resetState(state) {
    const initial = initState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
  setCanvasOwned(state,payload){
    state.canvasOwned = payload
  },
  setDragRemovedNote(state,payload){
    state.dragRemovedNote = payload
  },
  setDragAddNote(state,payload){
    state.dragAddNote = payload
  },
  setResetDrag(state,payload){
    state.resetDrag = payload
  },
  setTicket(state,payload){
    state.ticket = payload
  },
  setTicketToken(state,payload){
    state.ticketToken = payload
  }
};
