export default {
    getCountryDynamic(state){
        let country = []
        state.dataCountryTH.map((item)=>{
          country.push({label:item.name,value:item.numeric})
        })
        return country
    },
    getProvinceDynamic(state){
        let province = []
        state.provinceTh.map((item,index)=>{
            province.push({label:item,value:index+1})
        })
        return province
    },
    getCanvasList(state){
        let sort = state.canvasList.sort((a, b) => {
            let modifier = -1;
            if (a['createDate'] < b['createDate']) return -1 * modifier;
            if (a['createDate'] > b['createDate']) return 1 * modifier;
            return 0;
        })
        return sort
    },
    getTicket(state){
        if (state.ticket !== null) {
            let sort = state.ticket.comments.sort((a, b) => {
                let modifier = -1;
                if (a['createDate'] < b['createDate']) return -1 * modifier;
                if (a['createDate'] > b['createDate']) return 1 * modifier;
                return 0;
            })
            state.ticket.comments = sort   
        }
        return state.ticket
    }
};
