<template>
    <div>
        <b-loading :active.sync="isLoadingPage"></b-loading>
        <div class="pd-y-2 flex-js-between is-marginless">
            
            <div class="disablePrint flex-js-between is-flex">
                <nav class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
                <ul>
                    <li>
                        <a @click="backToList" class="text-color-title has-text-weight-normal">
                            Canvas list
                        </a> 
                    </li>
                    <li class="is-active">
                        <a v-if="canvas" aria-current="page">
                            {{canvas.canvasName}}
                        </a>
                    </li>
                </ul>
                </nav>
    
                <div v-if="canvas">
                    <b-dropdown ref="history" aria-role="list" position="is-bottom-left" class="db-filter hover-primary disablePrint mg-1">
                        <b-button 
                            class="decoration-none hover-primary has-text-weight-normal is-paddingless"
                            type="is-text"
                            icon-left="history"
                            slot="trigger"
                            :icon-:pack="(canvas.pattern === 'bmc')?'fas':null" >
                            Show history
                        </b-button>
                        <div class="modal-search">
                            <div class=" border-b">      
                                <p class="mg-b-2 has-text-weight-medium is-size-5">
                                    <b-icon icon="history" :pack="(canvas.pattern === 'bmc')?'fas':null" size="is-small"></b-icon>
                                    History
                                </p>
                            </div>
                            <div v-if="historyLimit.length > 0" class="historyBox">
                                <div v-for="(item,index) in historyLimit" :key="item._id" 
                                    class="pd-3  mg-x-2" 
                                    :class="{'border-t':index > 0}" >
                                    <p class="is-size-6 has-text-grey-dark  ">
                                        {{gethistoryDetail(item)}}
                                    </p>
                                    <p class="is-size-6 has-text-right has-text-grey-light">
                                        {{item.createDate | dateTime}}
                                    </p>
                                </div>
                                <div class="has-text-centered" :class="{'is-invisible':(historyLimit.length < history.length)?false:true }">
                                    <a @click="loadMoreHistory"  class="is-size-6 has-text-weight-medium has-text-grey-lighter viewMore">View more history</a>
                                </div>
                            </div>
                            <div v-else class="historyBoxNotData pd-2 has-text-centered">
                                <p>*** Not data history ***</p>
                            </div>
                        </div>
                    </b-dropdown>
                </div>
            </div>
            
          <div class="level">
                <p class="has-text-left">
                    <span class="has-text-weight-normal  is-size-5">Canvas Name:</span>
                    <span v-if="canvas" class="pd-l-2 is-size-6 text-color-title th">{{canvas.canvasName}}</span>
                    <small v-if="canvas" class="pd-l-2 color-gray">สร้างเมื่อ {{canvas.createDate | dateTime}}</small>
                    <span class="mg-l-3" v-if="statusSaving != 0"> 
                        <span class="has-text-warning" v-if="statusSaving == 1">
                            <i class="fas fa-spinner fa-pulse"></i> กำลังบันทึก
                        </span>
                        <span class="has-text-success" v-if="statusSaving == 2">
                            <i class="fas fa-check"></i> บันทึกสำเร็จ
                        </span>
                    </span>
                </p>
                <div class="is-flex flex-ai-center">
                    <a size="is-small"
                        v-show="role === 1"
                        @click="isInvite=true"
                        class="mg-1 is-size-6 disablePrint has-text-weight-normal fix-btn-primary">
                        <b-icon
                            icon="plus"
                            class="inviteIcon"
                            size="is-small">
                        </b-icon>
                        Invite
                    </a>
                    <div class="showOnlyPrint">
                        <p class="has-text-right">
                            <span class="has-text-weight-medium is-size-6">Created by:</span>
                            <span class="pd-l-2 is-size-7">{{canvasOwned.name}} {{canvasOwned.surname}}</span>
                        </p>
                    </div>
                </div>     
            </div>
        </div>
        
        <div v-if="user.fromType === 'invite'" class="pd-y-2 disablePrint">
            <p class="has-text-left">
                <span class="has-text-weight-medium  is-size-5">Created by:</span>
                <span class="pd-l-2 is-size-6">{{canvasOwned.name}} {{canvasOwned.surname}}</span>
            </p>
        </div>
        <div v-if="canvas" class="over-x-auto">
            <div v-if="canvas.pattern == 'navigator'" >
                <CanvasNavigator @statusSave="statusSave"  @setLoadding="setLoadding" @fetchCanvas="fetchCanvas" :canvas="canvas"/>
            </div>

            <div v-else >
               <CanvasLeanAndBmc  @statusSave="statusSave"  @setLoadding="setLoadding" @fetchCanvas="fetchCanvas" :canvas="canvas"/>
            </div>
            
            <div v-if="renderThumnbnail" ref="printMe" class="canvasThumbnail disablePrint hidden-respone">
                <CanvasExportThumbnail :canvas="canvas"/>
            </div>
        </div>
        <div v-if="canvas && canvas.pattern == 'navigator'" >
            <div class="mg-y-5 has-text-centered disablePrint">
                <img src="@/assets/logoAvocado.png" />
            </div>
        </div>
        <div class="btn-print disablePrint" @click="onPrint()">
            <i class="fas fa-print"></i>
        </div>
       
        <b-modal :active.sync="isInvite" :width="640" @close="resetInviteModal()">
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title has-text-left has-text-weight-normal">Invite to Canvas</p>
                </header>
        
                <section class="modal-card-body has-text-left">
                    <b-field  
                        label="Email" 
                        :type="{'is-danger':errors.has('email')}"
                        :message="errors.has('email')?'กรุณากรอกอีเมล':''">
                        <b-input
                            class="is-medium"
                            type="text"
                            v-model="emailInvite"
                            placeholder="Email address"
                            v-validate="'required|email'" 
                            name="email"
                            expanded
                        ></b-input>
                    </b-field>

                    <b-field
                        label="Role" 
                        :type="{'is-danger':errors.has('role')}"
                        :message="errors.has('role')?'กรุณาเลือกสิทธิ์การใช้งาน':''">
                        <b-select 
                            placeholder="Select  role" 
                            class="is-medium"
                            type="text"
                            v-model="roleInvite"
                            v-validate="'required'" 
                            name="role"
                            expanded>
                            <option :value="0">Read</option>
                            <option :value="1">Edit</option>
                        </b-select>
                    </b-field>
                </section>
                <footer class="modal-card-foot flex-js-end">
                    
                    <button class="button btn-min-w"  @click="resetInviteModal">Close</button><button class="button is-info btn-min-w" @click="inviteToBoard">Send invitation</button>
                </footer>
            </div>
        </b-modal>
    </div>
</template>
<script>

import CanvasExportThumbnail from './CanvasExportThumbnail'
import CanvasNavigator from './CanvasNavigator'
import CanvasLeanAndBmc from './CanvasLeanAndBmc'
import {HTTP,HTTPTicket} from '@/service/axios'
// import {msgFailError,msgCatchError} from '@/service/alertMsg'

export default {
    name:'CanvasManage',
    components:{
        CanvasExportThumbnail,
        CanvasNavigator,
        CanvasLeanAndBmc
    },
    data(){
        return {
            statusSaving:0,
            history:[],
            limit:10,
            isInvite:false,
            emailInvite:null,
            roleInvite:null,
            isLoadingPage:false,
            renderThumnbnail:false,
            ticket:null,
            canvasId:'',
            canvas:null,
            patternList:[]
        }
    },
    computed: {
        token(){
            return this.$store.state.token
        },
        ticketToken(){
            return this.$store.state.ticketToken
        },
        user(){
            return this.$store.state.user
        },
        historyLimit(){
            let sort = this.history
            sort.sort((a, b) => {
                if (a.createDate < b.createDate) return 1;
                if (a.createDate > b.createDate) return -1;
                return 0;
            });
            return sort.slice(0,this.limit)
        },
        role(){
            return this.$store.state.role
        },
        canvasOwned(){
            return this.$store.state.canvasOwned
        },
        page(){
            return this.$store.state.page
        }
    },
    sockets:{
        getCanvasHistory(res){
            this.history = res.obj
        }
    },
    mounted() {
        this.setParams()
        if (this.canvas) {
           this.fetchHistory() 
        }
        this.fetchPatternAll()
        this.createUserTicket()
        this.fetchCanvas()
        
        if(this.page === 'canvasDetail'){
            this.exportThumbnail()
            setInterval(() => {
                this.exportThumbnail()
            },1000*60*10);  
        } 
    },
    methods: {
        setParams() {
            if (typeof this.$route.query.id != 'undefined') {
                this.canvasId = this.$route.query.id;
            }
        },
        fetchCanvas(){
            let obj = {
                canvasId: this.canvasId
            };

            HTTP.post(`get/canvas/byId`, obj)
                .then((res) => {
                if (res.data.success) {
                    let pattern = this.patternList.filter((filter)=>{
                        return filter._id == res.data.obj.patternId
                    })

                    let canvas = JSON.parse(JSON.stringify(res.data.obj));
                    canvas["pattern"] = (pattern.length > 0)?pattern[0].partentName.toLowerCase():''
                    this.canvas = canvas
                } else {
                    if (res.data.message == "invalid authorization") {
                        this.expireToken();
                    }
                }
            })
            .catch((e) => {
                this.alertCatchError();
                console.log("fetchCanvas error", e);
            }).finally(()=>{
                setTimeout(() => {
                    this.isLoadingPage = false;
                }, 500);
            })
        },
        fetchPatternAll() {
            HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
            HTTP.post(`get/all/pattern`)
            .then((res) => {
                if (res.data.success) {
                    this.patternList = res.data.obj;
                } else {
                    if (res.data.message == "invalid authorization") {
                    this.expireToken();
                    } else {
                    this.patternList = [];
                    }
                }
            })
            .catch((e) => {
            console.log("get/all/pattern/error", e);
            });
        },
        statusSave(data){
            this.statusSaving = data
            setTimeout(() => {
                this.statusSaving = 0
            }, 3000);
        },
        setLoadding(status){
            this.isLoadingPage = status
        },
        createUserTicket(){
            let obj={
                name:this.user.name,
                surname:this.user.surname,
                email:this.user.email,
                typeSystem:'canvas'
            }
            HTTPTicket.post(`api/ticket/public/create/user`,obj)
            .then(res => {   
                if (res.data.success) {
                    this.$store.commit('setTicketToken',res.data.tokenAuth)
                    if (this.canvas && typeof this.canvas.codePublic !== 'undefined' && this.canvas.codePublic !== '') {
                        this.getTicketPublic()
                    }else{
                        if (res.data.message == 'invalid authorization') {
                            this.expireToken()
                        }else{
                            
                             this.createTicketPublic()
                          
                           
                        }
                    }
                    
                }
            })
            .catch(e => {
                console.log("api/ticket/public/create/user/error",e);
            });
        },
        async getTicketPublic(){
            let obj={
                email: this.user.email,
                roleId: this.canvas._id,
                codePublic: this.canvas.codePublic
            }
            await this.$store.dispatch('fetchTicketPublic',obj)
        },
        createTicketPublic(){
            if (this.canvas) {
            let obj={
                    subject:this.canvas.canvasName,
                    detail:"",
                    status:1,
                    postFile:[],
                    severityStatus:2,
                    typeSystem:"canvas",
                    roleId:this.canvas._id
                }
                HTTPTicket.defaults.headers.common.Authorization = `Bearer ${this.ticketToken}`;
                HTTPTicket.post(`api/create/ticket/public`,obj)
                .then(res => {
                    if (res.data.success) {
                        this.updateCodePublic(res.data.obj)
                        
                    }else{
                        if (res.data.message == 'invalid authorization') {
                            this.expireToken()
                        }
                    }
                })
                .catch(e => {
                    console.log("api/create/ticket/public/error",e);
                });
            }
        },
        updateCodePublic(ticket){
            HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
            let obj={
               canvasId:this.canvas._id,
               codePublic:ticket.codePublic
            }
            
            HTTP.post(`update/code/public`,obj)
            .then(res => {
                if (res.data.success) {
                    this.$store.commit('setTicket',ticket)
                    this.$store.commit('setCanvasCodeTicket',ticket.codePublic)
                }else{
                    if (res.data.message == 'invalid authorization') {
                        this.expireToken()
                    }
                }
            })
            .catch(e => {
                console.log("update/code/public/error",e);
            });
        },
        backToList(){
            this.$store.commit('setPage','canvasList')
        },
        onPrint(){
            window.print()
        },
        fetchHistory(){
            HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
            let obj={
               canvasId:this.canvas._id
            }
            HTTP.post(`get/canvas/history/ById`,obj)
            .then(res => { 
                if (res.data.success) {
                    this.history=res.data.obj
                }else{
                    if (res.data.message == 'invalid authorization') {
                        this.expireToken()
                    }
                }
                this.isLoadingPage = false
            })
            .catch(e => {
                console.log("/get/all/canvas/error",e);
            });
        },
        loadMoreHistory(){
            this.limit = this.limit + 10
        },
        gethistoryDetail(history){
            
            let detail = history.subject + ' '+
                        history.verb + ''+
                        history.directObject + ' '+
                        history.directObjectName + ' '+
                        history.introduce + ''+
                        history.indirect + ' '+
                        history.indirectName + ' '+
                        history.verb2 + ' '+
                        history.subIndirect + ''+
                        history.subIndirectName + ''
            return detail
        },
        resetInviteModal(){
            this.isInvite = false
            setTimeout(() => {
                this.emailInvite = null
                this.roleInvite = null
            }, 500);
        },
        inviteToBoard(){
            this.$validator.validate().then(valid => {
                if (valid) {
                    this.isLoadingPage = true
                    let obj ={
                        canvasId:this.canvas._id,
                        userId:this.user.userId,
                        email:this.emailInvite,
                        role:this.roleInvite
                    }
 
                    HTTP.post(`link/invite`,obj)
                    .then(res => {
                        // console.log("invite", res);  
                        if (res.data.success) {
                            this.$buefy.toast.open({
                                message:'Succeed!',
                                type: 'is-success'
                            })
                            this.resetInviteModal()
                        }else{
                            if (res.data.message == 'invalid authorization') {
                                this.expireToken()
                            }else{
                                this.createTicketPublic()
                            }
                            this.alertFailError()
                        }
                        this.isLoadingPage = false
                    })
                    .catch(e => {
                        console.log("invite/error",e);
                        this.isLoadingPage = false
                        this.alertCatchError()
                    });
                }
            })
        },
        exportThumbnail() {
            this.renderThumnbnail = false
            setTimeout(() => {
                this.renderThumnbnail = true
            },100)

            setTimeout(async() => {
                const el = this.$refs.printMe;
                // const el = this.$refs.allScreen;
                // add option type to get the image version
                // if not provided the promise will return 
                // the canvas.
                const options = {
                    type: 'dataURL',
                    // height:this.maxHeight,
                    // width:360,
                    // windowHeight:'100%',
                    // windowWidth:'100%',
                    scale:1,
                    logging:false
                }

                let output =  await this.$html2canvas(el, options);
    
                let outputResize = await this.resizedataURL(output, 680, 500);
 
                this.updateCanvasThumb(outputResize)
            }, 500);
            
        },
        resizedataURL(datas, wantedWidth, wantedHeight){
            return new Promise(function(resolve){

                // We create an image to receive the Data URI
                var img = document.createElement('img');

                // When the event "onload" is triggered we can resize the image.
                img.onload = function()
                {        
                    // We create a canvas and get its context.
                    var canvas = document.createElement('canvas');
                    var ctx = canvas.getContext('2d');

                    var srcWidth = img.width,
                        srcHeight = img.height;

                    var ratio = Math.min(wantedWidth / srcWidth, wantedHeight / srcHeight);

                    // We set the dimensions at the wanted size.
                    canvas.width = srcWidth*ratio;
                    canvas.height = srcHeight*ratio;

                    // We resize the image with the canvas method drawImage();
                    ctx.drawImage(this, 0, 0, srcWidth*ratio, srcHeight*ratio);

                    var dataURI = canvas.toDataURL();

                    // This is the return of the Promise
                    resolve(dataURI);
                };

                // We put the Data URI in the image's src attribute
                img.src = datas;

            })
        },
        updateCanvasThumb(img){
            
            let obj={
                canvasId:this.canvas._id,
                thumbnail:img
            }
            
            HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
            HTTP.post(`api/update/thumb/nail`,obj)
            .then((res) => {

            })
            .catch(e => {
                console.log("api/update/thumb/nail/error",e);
            });
        },
    }
    
}
</script>
<style scoped>
.canvasThumbnail{
    min-height: 80vh!important;
    width: 2850px!important;
    position: absolute;
    top: 0;
    z-index: -99999;
    
}
.historyBox{
    max-height: 600px;
    overflow-y: auto;
    margin:5px 0 5px 0;
    word-break: break-all;
}
.historyBoxNotData{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
}
.hisoryList{
    border-bottom: 1px solid #dbdbdb;
    margin: 0px 10px 0px 10px;
}
.viewMore:hover{
    color: #363636!important;
}
.inviteIcon{
    width: 0.5rem!important;
}
.btn-print{
  cursor: pointer;
  position: fixed;
  bottom: 15px;
  right: 40px;
  background: #F47521;
  padding: 15px 20px;
  border-radius: 50%;
  color: #fff;
  border:1px solid #fff;
  z-index: 999;
}
@media only screen and (max-width: 768px) {
    .hidden-respone{
        display: none;
    }
}

</style>
